import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuLink = ({ name, href, Icon }) => {
  const { pathname } = useLocation();
  const active = pathname.indexOf(href) >= 0;

  return (
    <Link
      key={name}
      to={href}
      className={classNames(
        active
          ? "bg-gray-200 text-black"
          : "text-black hover:bg-gray-50 hover:text-gray-900",
        "group flex items-center px-2 py-2 text-base font-medium rounded-3xl"
      )}
    >
      <Icon
        className={classNames(
          active ? "text-black" : "text-black group-hover:text-gray-500",
          "mr-4 h-6 w-6"
        )}
        aria-hidden="true"
      />
      <FormattedMessage id={name} />
    </Link>
  );
};

export default MenuLink;
