import React, {useCallback, useContext, useState} from "react";

// Services
import apiRequest from "../../../services/apiRequest";
import {NotificationsContext} from "../../Notifications";
import {FormattedMessage, useIntl} from "react-intl";

// UI
import Input from "../../Input";
import Select from "../../Select";
import Modal from "../../Modal";
import Button from "../../Button";
import InputMultiLine from "../../InputMultiLine";
import FilePicker from "../../FilePicker";
import AuthenticationService from "../../../services/AuthenticationService";
import { useAuth0} from '@auth0/auth0-react';

function MapLanguageKey(intl) {
  const options_category = [
    {
      value: "service_not_available",
      name: intl.formatMessage({id: "category_one"}),
      map: "infrastructural",
    },
    {
      value: "results_not_received",
      name: intl.formatMessage({id: "category_two"}),
      map: "infrastructural",
    },
    {
      value: "unexpected_results",
      name: intl.formatMessage({id: "category_three"}),
      map: "algorithmic",
    },
    {
      value: "system_break",
      name: intl.formatMessage({id: "category_four"}),
      map: "infrastructural",
    },
    {
      value: "other",
      name: intl.formatMessage({id: "category_five"}),
      map: "software",
    },
  ];
  const options_risk = [
    {value: "LOW", name: intl.formatMessage({id: "risk_one"})},
    {value: "MEDIUM", name: intl.formatMessage({id: "risk_two"})},
    {value: "HIGH", name: intl.formatMessage({id: "risk_three"})},
  ];
  return {options_category, options_risk};
}

const getUserEmail = () =>{
  return AuthenticationService.getUserData().email;
}

function DialogTicket({opened, toggleOpen, setShowSuccessResponse}) {
  const { user } = useAuth0();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {push} = useContext(NotificationsContext);
  const [row, setRow] = useState({
    hs_ticket_category: undefined,
    hs_ticket_priority: undefined,
    subject: undefined,
    content: undefined,
    user_email: user.email,
    files: undefined,
    fileSizeExceeded: false
  });
  const option = MapLanguageKey(intl);

  function cleanRow() {
    setRow({
      hs_ticket_category: undefined,
      hs_ticket_priority: undefined,
      subject: undefined,
      content: undefined,
      user_email: getUserEmail(),
      files: undefined,
      fileSizeExceeded: false
    });
  }

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (
          !row.hs_ticket_category ||
          !row.hs_ticket_priority ||
          !row.subject ||
          !row.content
        ) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        let formData = new FormData();

        if (row.files && !row.fileSizeExceeded) {
          row.files.forEach(elem => {
            formData.append("files", elem, elem.name);
          })
        }

        // obtain correct category value
        let temp = option.options_category.filter(
          (x) => x.value === row.hs_ticket_category
        )[0];

        Object.keys(row).forEach((key) => {
          if (key === 'hs_ticket_category')
            formData.append(key, temp['map']);
          else if (key !== 'files')
            formData.append(key, row[key]);
        });

        // eslint-disable-next-line
        apiRequest
          .post("/tickets", formData)
          .then( response => {
            if (response.status_code === 200) {
              setLoading(false);
              toggleOpen();
              setShowSuccessResponse(true);

              // if request is correct, this resets the modal fields
              cleanRow();
            } 
          })
          .catch((error) => {
            if (error.response.status === 500)
              push({
                title: error.response.data.detail,
                type: "error",
              });
            else 
              push({
                title: "Errore imprevisto",
                type: "error",
              });
            setLoading(false);
          });

      } catch (error) {
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      }
    },
    [row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={opened} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="modal_title"/>
          </h3>
        </div>

        <form id="new-ticket-form" onSubmit={_onSubmit} className="flex flex-col gap-y-5">
          <div>
            <Select
              customStyles={{borderRadius: '6px'}}
              options={[
                {value: "problemType", name: intl.formatMessage({id: "select"})},
              ].concat(option.options_category)}
              value={row.hs_ticket_category}
              onChange={(e) => {
                setRow({...row, hs_ticket_category: e.target.value});
              }}
              label={intl.formatMessage({id: "type_problem"})}
              errorMessage="Seleziona un'opzione dal menu a tendina."
              required
            />
          </div>
          <div>
            <Select
              customStyles={{borderRadius: '6px'}}
              options={[
                {value: "problemDescription", name: intl.formatMessage({id: "select"})},
              ].concat(option.options_risk)}
              value={row.hs_ticket_priority}
              onChange={(e) =>
                setRow({...row, hs_ticket_priority: e.target.value})
              }
              label={intl.formatMessage({id: "measure_problem"})}
              errorMessage="Seleziona un'opzione dal menu a tendina."
              required
            />
          </div>
          <div>
            <Input
              value={row.subject}
              onChange={(e) => {
                setRow({...row, subject: e.target.value});
              }}
              label={intl.formatMessage({id: "subject_problem"})}
              type="text"
              errorMessage={intl.formatMessage({id: "error_message"})}
              required
            />
          </div>
          <div className="-mt-3">
            <InputMultiLine
              rows={5}
              value={row.content}
              onChange={(e) => {
                setRow({...row, content: e.target.value});
              }}
              label={intl.formatMessage({id: "describe_problem"})}
              type="text"
              errorMessage={intl.formatMessage({id: "error_message"})}
              required
            />
          </div>
          <div className="-mt-2">
            <FilePicker
              fileChosen={row.files}
              setFileChosen={values => setRow({...row, files: values})}
              maxSize={1}
              alertSizeExceeded={bool => setRow({...row, fileSizeExceeded: bool})}
              labelCustomStyle={{color: 'black'}}
            />
          </div>
          <div className="mt-6 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              <FormattedMessage id="send_problem"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default DialogTicket;
