import React from "react";

const MODEL_PERMISSIONS = {
    READ: 1,
    UPDATE: 2,
    DELETE: 4,
    SHARE: 8,
    ADMIN: 15
}

function ModelCheckAuthorization({
    permission, // {}
    check, // 15
    children
}) {
    if(!permission || ! check) {
        return null;
    }

    if(permission.permissions >= check) {
        return <>{children}</>;
    }

    return null;
}



export default ModelCheckAuthorization;
export {
    MODEL_PERMISSIONS
}