import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "../../ui-components/PrivateRoute";
import ModelsHomepage from "./components/Homepage";
import ModelDetail from "./components/ModelDetail";
import { ModelSettingsDataProvider } from "./data";

function Models() {
  const { path } = useRouteMatch();
  
  
  return (
    <ModelSettingsDataProvider>
      <Switch>
        <PrivateRoute exact path={path} component={ModelsHomepage} />
        <PrivateRoute path={`${path}/:modelId/version/:versionId`} component={ModelDetail} />
        <PrivateRoute path={`${path}/:modelId`} component={ModelDetail} />

      </Switch>
    </ModelSettingsDataProvider>
  );
}

export default Models;
