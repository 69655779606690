import {
  QueueListIcon,
} from "@heroicons/react/24/outline";
import Models from "@pages/Models"

const routes = [
  {
    name: "Modelli",
    href: "/models",
    
    Icon: QueueListIcon,
    component: Models,
  },
  
];

export default routes;
