import React, { useEffect, useState, useContext, useMemo } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { ModelsSettingsDataContext } from "../data";

import { NotificationsContext } from "../../../ui-components/Notifications";
import Badge from "../../../ui-components/Badge";
import Input from "../../../ui-components/Input";
import Tooltip from "../../../ui-components/Tooltip";
import Button from "../../../ui-components/Button";
import FilePicker from "../../../ui-components/FilePicker";
import SlideOver from "../../../ui-components/SlideOver";
import CheckboxGroup from "../../../ui-components/CheckboxGroup";
import Toggle from "../../../ui-components/Toggle"

import { getCurrentDateAndTime } from "../../../utils";
import { CubeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function ModelsHomepage() {

  const [searchInput, setSearchInput] = useState("");
  const [openAddModelSlideOver, setOpenAddModelSlideOver] = useState(false);
  const [currentDate, setCurrentDate] = useState();

  const { path } = useRouteMatch();

  const { models, getModels } = useContext(ModelsSettingsDataContext);

  const filteredModels = useMemo(() => {
    if(!models) return [];

    return models
      .filter((model) => {
        return searchInput === "" ? true : model.name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0;
      })
      .sort((a, b) => {
        if(a.name < b.name) return -1;
        return 1;
      })
  }, [models, searchInput]);

  useEffect(() => {
    const getM = async () => {
      await getModels();
    }

    getM();
    setCurrentDate(getCurrentDateAndTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen">
      <div className="flex flex-row mt-20 mb-4 space-x-6">
        <h1 className="font-bold text-4xl text-am-800">
          Gestione modelli
        </h1>

        <Button
          onClick={() => {
            setOpenAddModelSlideOver(true);
          }}
        >
          AGGIUNGI UN MODELLO
        </Button>
      </div>

      <div className="flex flex-row items-center justify-between space-x-4 mb-4">
        <div className="flex flex-row items-center space-x-3 opacity-30">
          <span className="font-[500]">Modelli disponibili: {models && models.length}</span>
          <span className="font-[200]">Ultimo aggiornamento: {currentDate}</span>
        </div>

        <div className="flex flex-row items-center space-x-2">
          <MagnifyingGlassIcon className="h-6 w-6 opacity-40"/>
          <Input value={searchInput} onChange={(e) => {
              setSearchInput(e.target.value);
            }} placeholder="Nome del modello">  
          </Input>
        </div>
      </div>

      {
        models && models.length > 0 ? (
          <div className="mb-4">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {
              filteredModels.map((model) => {
                const href = `${path}/${model.id}`;
                const isoDate = new Date(model.created_at);
                const date = isoDate.toLocaleDateString();

                return ( 
                  <li key={model.id} className="transition duration-200 transform ease-in-out hover:-translate-y-2 col-span-1 divide-y divide-gray-200 rounded-3xl bg-white shadow">
                    <Link to={href}>
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                              <CubeIcon className="w-8 h-8" />
                              <h3 className="truncate text-sm font-medium text-gray-900">{model.name}</h3>
                              <Badge text={model.type === "ml" ? "Machine Learning" : "Rete neurale"} type={model.type === "ml" ? "info" : "info2"}></Badge>
                          </div>
                          <p className="mt-1 truncate text-sm text-gray-500">{model.description ? model.description : "Nessuna descrizione disponibile"}</p>
                          <p className="mt-1 truncate text-sm text-gray-300">Creato il: {date}</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })
            }
          </ul>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-3/4">
            <button
              type="button"
              className="relative block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={() => {
                setOpenAddModelSlideOver(true);
              }}
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-medium text-gray-900">Non hai nessun modello! Creane uno</span>
            </button>
          </div>
        )
      }

      <AddModelSlideOver
        open={openAddModelSlideOver}
        setOpen={setOpenAddModelSlideOver}
        models={models}
      />

    </div>
  );
}

function AddModelSlideOver({
  open,
  setOpen,
  models
}) {
  const DEFAULT_VERSION_FORM_DATA = useMemo(() => { 
      return {
      "name": undefined,
      "type": undefined,
      "model_description": undefined,
      "description": undefined,
      "bitbucket_repository" : undefined,
      "bitbucket_commit": "",
      "storage_type": "s3",
      "model_tags": [],
      "validator_id": 0
    }
  }, [])

  const DEFAULT_PERMISSION_FORM_DATA = {
    "entity_type": "",
    "entity_identifier": "",
    "permissions": 1
  }

  const DEFAULT_DATASET_FORM_DATA = {
    "type": "",
    "version_id": undefined,
    "artifact" : undefined,
    "isAwsExternal": false,
    "storage_path": undefined,
    "external_aws_account_bucket": undefined,
    "external_aws_account": {
      "account_id": undefined,
      "account_name": undefined,
      "s3_access_role_name": undefined
    },
  }

  const [modelFormData, setModelFormData] = useState(DEFAULT_VERSION_FORM_DATA);

  const [isNewModel, setIsNewModel] = useState();
  const [modelLoadStep, setModelLoadStep] = useState(0);

  const [modelArtifactData, setModelArtifactData] = useState(undefined);

  const [datasetFormData, setDatasetFormData] = useState([]);
  //const [datasetArtifactData, setDatasetArtifactData] = useState([]);

  const [permissionFormData, setPermissionFormData] = useState([]);
  const [checkboxGroup, setCheckboxGroup] = useState([]);
  
  const { push } = useContext(NotificationsContext);

  const { addModel, addVersion, addDataset, addArtifactByVersionId, addArtifactByDatasetId, addModelAuthorization, removeModelById } = useContext(ModelsSettingsDataContext);

  const handleAddDataset = async () => {
    if(datasetFormData.length === 0) return;

    datasetFormData.forEach(async (dataset, index) => {
      if(
        dataset.type === undefined ||
        dataset.version_id === undefined ||
        (dataset.isAwsExternal === false  && dataset.artifact === undefined) ||
        dataset.isAwsExternal &&
        (
          dataset.storage_path === undefined ||
          dataset.external_aws_account_bucket === undefined ||
          dataset.external_aws_account.account_id === undefined ||
          dataset.external_aws_account.account_name === undefined ||
          dataset.external_aws_account.s3_access_role_name === undefined
        )
      ) {
        push({
          title: "Stato dataset",
          text: "Campi mancanti!"
        })
        return;
      }

      const modifiableDataset = JSON.parse(JSON.stringify(dataset));
      const isAwsExternal = modifiableDataset.isAwsExternal;
      let artifact;

      if(dataset.artifact) {
        artifact = dataset.artifact;
      }

      if(!isAwsExternal) {
        delete modifiableDataset.external_aws_account
      }
      delete modifiableDataset.artifact;
      delete modifiableDataset.isAwsExternal;
      
      try {
        // 1. Creating model
        push({
          title: "Stato dataset",
          text: "Aggiungo il dataset (1/2)"
        })
        const datasetCreated = await addDataset(modifiableDataset);

        push({
          title: "Stato dataset",
          text: "Carico il file del dataset (2/2)"
        })

        const datasetId = datasetCreated.id;
        if(!isAwsExternal) {
          const fileData = new FormData();
          fileData.append("dataset_file", artifact[0])
          console.log("DATASET FILE DATA", fileData.get("dataset_file"));

          try {
            await addArtifactByDatasetId(datasetId, fileData);

            push({
              title: "Stato dataset",
              text: "Dataset aggiunto con successo!"
            })
            setOpen(false)
          } catch(err) {
            push({
              title: "Impossibile caricare l'artefatto",
              text: "L'artefatto sembra essere già essere presente!"
            })
            console.warn("Error during artifact creation ", err);
          }
        }
      } catch(error) {
        console.error(error);
        push({
          title: "Impossibile creare il dataset",
          text: error
        })
      }
    })
  }

  const st = [
    { 
      name: 'Aggiungi un modello e/o una versione', 
      description: 'Aggiungi un nuovo modello con una versione ad AMR',
      title: <span className="truncate text-xl font-bold text-gray-900">{isNewModel ? <h1 className="text-xl">Crea nuovo modello</h1> : <h1 className="text-xl">Aggiungi versione a "{modelFormData && modelFormData.name}"</h1>}</span>, 
      href: '#', 
    },
    {
      name: 'Crea e/o aggiungi dataset (optional)',
      description: 'Seleziona i dataset utilizzati da questo modello',
      title: <h1 className="truncate text-xl font-bold text-gray-900">Crea e/o aggiungi dataset (opzionale)</h1>,
      href: '#',
    },
    { 
      name: 'Condividi il modello (optional)', 
      description: 'Aggiungi permessi ad altri utenti', 
      title: <h1 className="truncate text-xl font-bold text-gray-900">Condividi il modello (opzionale)</h1>,
      href: '#',
    },
  ];

  useEffect(() => {
    const calculatePermissions = () => {
      checkboxGroup.forEach((group, index) => {
        const permissionValue = group.reduce((partialSum, a) => partialSum + a, 0);
        setFormDataValue("permissions", permissionValue, "permission", index);
      })
    }

    calculatePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxGroup])

  useEffect(() => {

    console.log("INSIDE USEEFFECT", datasetFormData)
    if(datasetFormData && datasetFormData.some((dataset) => dataset.version_id !== undefined)) {
      const addD = async () => { 
        await handleAddDataset(); 
      }

      addD()//.then(() => setDatasetFormData(DEFAULT_DATASET_FORM_DATA));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetFormData])
  

  useEffect(() => {
    if(models && open && !isNewModel) {
      const model = models.filter(model => modelFormData.name === model.name);

      if(model.length !== 0) {
        setFormDataValue("type", model[0].type, "model");
        setFormDataValue("model_description", model[0].description, "model");
        setFormDataValue("bitbucket_repository", model[0].bitbucket_repository, "model");
      }
    } 
    else if(isNewModel && modelFormData.name !== undefined) {
      setFormDataValue("type", undefined, "model");
      setFormDataValue("model_description", undefined, "model");
      setFormDataValue("bitbucket_repository", undefined, "model");
    }
    else setModelFormData(DEFAULT_VERSION_FORM_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewModel])

  const setFormDataValue = (key, value, type, index = -1) => {
    console.log("INDEX", index);
    console.log("KEY", key);
    console.log("KEY", value);

    if(key === "model_tags") {
      setModelFormData(oldValue => {
        return {
          ...oldValue,
          [key]: value.length === 0 ? [] : 
                  value[0].label && value.length === 1 ? [value[0].label] : 
                    value[0].label ? oldValue.model_tags.filter(tag => {
                      return value.some((element) =>  {
                        return tag === element.label
                      })
                    }) : 
                      oldValue.model_tags.concat(value)
        }
      })
    } else if(type === "model") {
      setModelFormData(oldValue => {
        return {
          ...oldValue,
          [key]: value
        }
      })
    }

    if(type === "dataset" && index !== -1) {
      setDatasetFormData(oldValue => {
        let newValue = oldValue.slice();
        newValue[index] = {
          ...newValue[index],
          [key]: value
        }
        return newValue;
      })
    } else if(type === "dataset" && index === -1) {
      console.log("CIAO");
      setDatasetFormData(oldValue => {
        let newValue = oldValue.slice();
        newValue.forEach((dataset, idx) => {
          newValue[idx] = {
            ...dataset,
            [key]: value
          }
        })
        console.log("NEWVALUE", newValue);
        return newValue;
      })
    }

    if(type === "permission") {
      setPermissionFormData(oldValue => {
        let newValue = oldValue.slice();
        newValue[index] = {
          ...newValue[index],
          [key]: value
        }
        return newValue;
      })
    }
  }

  const handleAddPermissions = async (modelId) => {
    if(permissionFormData.length === 0) return;
    
    permissionFormData.forEach(async (permission, index) => {
      if(
        permission.entity_identifier === "" && 
        permission.entity_type === "" && 
        checkboxGroup[index].length === 0
      ) {
        push({
          title: "Stato permessi",
          text: "Campi mancanti"
        })
        return;
      }

      try {
        push({
          title: "Stato permessi",
          text: "Aggiungo i permessi.."
        })

        await addModelAuthorization(modelId, permission);

        push({
          title: "Stato permessi",
          text: "Permessi aggiunti con successo!"
        })

      } catch (error) {
        push({
          title: "Stato permessi",
          text: error.message
        })
        console.error(error);
      }
    })
  }

  const handleAddModel = async () => {
    if(!isNewModel) {
    }

    if(modelFormData.name === undefined || 
      modelFormData.type === undefined ||
      modelFormData.description === undefined ||
      modelFormData.model_description === undefined ||
      modelFormData.bitbucket_repository === undefined ||
      modelFormData.model_tags.length === 0 ||
      modelArtifactData === undefined
    ) {
      push({
        title: "Stato modello",
        text: "Campi mancanti!"
      })
      return;
    }
    
    try {
      // 1. Creating model
      push({
        title: "Stato modello",
        text: "Aggiungo il modello (1/3)"
      })
      const modelCreated = await addModel({
        "name": modelFormData.name,
        "type": modelFormData.type,
        "description": modelFormData.model_description,
        "bitbucket_repository": modelFormData.bitbucket_repository
      });

      // 2. Creating version
      push({
        title: "Stato modello",
        text: "Aggiungo la versione (2/3)",
      });
      delete modelFormData.model_description;

      // TODO: wrap in try catch
      let versionCreated;
      
      try {
        versionCreated = await addVersion(modelFormData);
      } catch (error) {
        await removeModelById(modelCreated.id);
        push({
          title: "Impossibile caricare la versione",
          text: "Errore con il caricamento della versione"
        })
        console.warn("Error during version creation ", error);
        return;
      }
      
      // 3. creating Artifact
      const versionId = versionCreated.id;
      const fileData = new FormData();
      fileData.append("version_file", modelArtifactData[0])
      console.log("MODEL FILE DATA", fileData.get("version_file"))

      push({
        title: "Stato modello",
        text: "Carico il dataset (3/3)",
      })

      try {
        await addArtifactByVersionId(versionId, fileData);
        push({
          title: "Stato modello",
          text: "Upload completato con successo!",
        })  
        setModelFormData(DEFAULT_VERSION_FORM_DATA);
        setModelArtifactData();
        setIsNewModel(null);

      } catch(err) {
        await removeModelById(modelCreated.id);
        push({
          title: "Impossibile caricare l'artefatto",
          text: "L'artefatto sembra essere già essere presente!"
        })
        console.warn("Error during artifact creation ", err);
      }

      //4. Adding dataset
      setFormDataValue("version_id", versionId, "dataset")
      /*try {
        const datasetCreated = await handleAddDataset();
      } catch(err) {
        console.warn("Error during dataset creation", err);
      }*/

      //5. Adding permissions
      try {
        handleAddPermissions(modelCreated.id);
      } catch (err) {
        push({
          title: "Impossibile caricare i permessi",
          text: "Errore durante il caricamento dei permessi. Riprova"
        })
        console.warn("Error during permission creation ", err);
      }
    } catch(error) {
      console.error(error);
      push({
        title: "Impossibile creare il modello",
        text: "Seleziona un altro nome di modello oppure controlla l'artefatto inserito!"
      })
    }
    
  }

  const Header = () => {
    return (
      <div className="space-y-1">
        <div className="flex items-center space-x-3">
          <CubeIcon className="w-8 h-8"/>
          { st && st[modelLoadStep].title }
        </div>
        <p className="text-sm text-gray-500">
        </p>
      </div>
    );
  }

  console.log(datasetFormData);
  console.log(modelFormData);
  console.log(permissionFormData);
  console.log(checkboxGroup);

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={<Header />}
    >
      <div className="flex flex-col justify-center items-center">
        <nav aria-label="Progress" className="w-11/12 mb-6">
          <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
            {st && st.map((step, stepIdx) => (
              <li key={step.name} className="md:flex-1">
                {stepIdx < modelLoadStep ? (
                  <div
                    className="group flex flex-col border-l-4 border-am-600 py-2 pl-4 hover:border-am-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                  >
                    <span className="text-sm font-medium text-am-600 group-hover:text-am-800">{stepIdx + 1}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </div>
                ) : stepIdx === modelLoadStep ? (
                  <div
                    className="flex flex-col border-l-4 border-am-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                    aria-current="step"
                  >
                    <span className="text-sm font-medium text-am-600">{stepIdx + 1}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </div>
                ) : (
                  <div
                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                  >
                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{stepIdx + 1}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </nav>
        
        <div className="w-11/12">
          {/*steps && steps[modelLoadStep].payload*/}

          {
            modelLoadStep === 0 ? <div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                <div className="flex flex-row items-center">
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                    Nome modello<sup className="text-red-500 font-bold text-sm">*</sup>
                  </label>
                  <Tooltip text={`Seleziona un modello per aggiungere una versione, scrivi per crearne uno nuovo`} placement={"right"}/>
                </div>
      
                <div className="sm:col-span-2">
                  <CreatableSelect 
                    className="pb-2"
                    placeholder="Digita e premi invio per nuovo modello"
                    options={models && models.map((model) => {return {label: model.name, value: model.name}})}
                    value={modelFormData && modelFormData.name && {label: modelFormData.name, value: modelFormData.name}}
                    onChange={(e) => {
                      setFormDataValue("name", e.value, "model");
                      setIsNewModel(false);
                    }}
                    onCreateOption={(e) => {
                      setFormDataValue("name", e, "model");
                      setIsNewModel(true);
                    }}
                  />
                </div>
              </div>
              { 
                isNewModel && (
                  <> 
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Tipo modello<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Select
                          className="pb-2"
                          placeholder="Tipo di modello"
                          options={[{value: "ml", label : "Machine Learning"}, {value: "nn", label : "Neural Network"}]}
                          isSearchable
                          isClearable
                          value={modelFormData.type !== undefined && {label: modelFormData.type === "nn" ? "Neural Network" : "Machine Learning", value: modelFormData.type}}
                          onChange={(e) => {
                            if(e) setFormDataValue("type", e.value, "model");
                            else setFormDataValue("type", undefined, "model");
                          }}
                        />
                      </div>
                    </div>
      
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Descrizione modello<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Input
                          type="textarea"
                          value={modelFormData.model_description}
                          onChange={(e) => {
                            setFormDataValue("model_description", e.target.value, "model");
                          }}
                          placeholder="Descrizione"
                        />
                      </div>
                    </div>
                    
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Repository<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="flex flex-row items-center space-x-1 sm:col-span-2">
                        <span className="font-light opacity-50">https://bitbucket.org/energyway/</span>
                        <Input
                          value={modelFormData.bitbucket_repository}
                          onChange={(e) => {
                            setFormDataValue("bitbucket_repository", e.target.value, "model");
                          }}
                          placeholder="Repository Bitbucket"
                        />
                      </div>
                    </div>
      
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Validator
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Select
                          isClearable
                          className="pb-2"
                          placeholder="Validator"
                          options={[{value: 1, label : "aHead"}]}
                          isSearchable
                          value={modelFormData.validator_id && modelFormData.validator_id === 1 && {label: "aHead", value: modelFormData.validator_id}}
                          onChange={(e) => {
                            if(e) setFormDataValue("validator_id", e.value, "model");
                            else setFormDataValue("validator_id", 0, "model")
                          }}
      
                        />
                      </div>
                    </div>
                  </>
                )
              }
              {
                isNewModel != null && (
                  <> 
                    <hr></hr>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Descrizione versione<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Input   
                          type="textarea"
                          value={modelFormData.description}
                          onChange={(e) => {
                            setFormDataValue("description", e.target.value, "model");
                          }}
                          placeholder="Descrizione versione"
                        />
                      </div>
                    </div>
      
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Tags<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <CreatableSelect 
                          className="pb-2"
                          isMulti
                          placeholder="Digita e premi invio per aggiungere tag"
                          value={modelFormData.model_tags && modelFormData.model_tags.map((tag) => {return {label: tag, value: tag}})}
                          onCreateOption={(e) => {
                            setFormDataValue("model_tags", e, "model");
                          }}
                          onChange={(e) => {
                            setFormDataValue("model_tags", e, "model")
                          }}
                          isClearable={false}
                        />
                      </div>
                    </div>
      
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          File modello<sup className="text-red-500 font-bold text-sm">*</sup>
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <FilePicker 
                          className="pb-2"
                          fileChosen={modelArtifactData}
                          setFileChosen={file => setModelArtifactData(file)}
                        />
                        <span className="text-sm opacity-40">Formati supportati: .zip</span>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Hash commit Bitbucket versione
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Input
                          value={modelFormData.bitbucket_commit}
                          onChange={(e) => {
                            setFormDataValue("bitbucket_commit", e.target.value, "model");
                          }}
                          placeholder="Hash commit Bitbucket versione"
                        />
                      </div>
                    </div>
                  </>
                )
              }
            </div> : 
            modelLoadStep === 1 ? 
              <div> 
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full mb-16 align-middle md:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                            >
                              Tipo
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              File dataset
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              AWS esterno?
                            </th>
                            {

                              datasetFormData.some((dataset) => dataset.isAwsExternal === true) ? (
                                <>
                                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                    Path storage
                                  </th>
                                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                    Bucket account AWS esterno 
                                  </th>
                                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                    ID account AWS esterno
                                  </th>
                                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                    Nome account AWS esterno
                                  </th>
                                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                    Nome ruolo di accesso S3 account AWS esterno
                                  </th>
                                </>
                              ) : <></>
                            }
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 ">
                          { datasetFormData && datasetFormData.map((dataset, datasetIdx) => {
                            const ref = Object.freeze(datasetIdx);
                            console.log(ref);
                            return (
                              <tr key={ref}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                  <Select
                                    className="pb-2"
                                    placeholder="Tipo di dataset"
                                    options={[{value: "train", label : "train"}, {value: "validation", label : "validation"}, {value: "test", label : "test"}]}
                                    isSearchable
                                    isClearable
                                    value={dataset && dataset.type && {label: dataset.type, value: dataset.type}}
                                    onChange={(e) => {
                                      console.log("SELECT REF", ref)
                                      if(e) setFormDataValue("type", e.value, "dataset", ref);
                                      else setFormDataValue("type", undefined, "dataset", ref);
                                    }}
                                  />
                                </td>
                                {
                                  dataset.isAwsExternal ? <td className="text-center">N/A</td>  : 
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    <input 
                                      type="file"
                                      multiple={false}
                                      onChange={(e) => {
                                        let newList = [];
                                        Object.keys(e.target.files).forEach((key) => {
                                          newList.push(e.target.files[key]);
                                        });

                                        console.log("FILE REF", ref)

                                        setFormDataValue("artifact", newList, "dataset", ref)
                                      }}
                                    />
                                    {/*<FilePicker 
                                      className="pb-2"
                                      fileChosen={dataset.artifact}
                                      setFileChosen={(file, idx = ref) => {
                                        console.log("FILE REF", idx)

                                        setFormDataValue("artifact", file, "dataset", idx)
                                      }}
                                    />
                                    */}
                                  </td>
                                }
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  <Toggle 
                                    checked={dataset.isAwsExternal}
                                    onChange={() => {
                                      console.log("TOGGLE REF", ref)

                                      setFormDataValue("isAwsExternal", !dataset.isAwsExternal, "dataset", ref);
                                      if(!dataset.isAwsExternal === true) {
                                        setFormDataValue("artifact", undefined, "dataset", ref)
                                      } else {
                                        setFormDataValue("storage_path", undefined, "dataset", ref)
                                        setFormDataValue("external_aws_account_bucket", undefined, "dataset", ref)
                                        setFormDataValue("external_aws_account", {
                                          "account_id": undefined,
                                          "account_name": undefined,
                                          "s3_access_role_name": undefined
                                        }, "dataset", ref)
                                      }
                                    }}
                                  />
                                </td>
                                {
                                  dataset.isAwsExternal ? 
                                    ( 
                                      <>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          <Input 
                                            value={dataset && dataset.storage_path}
                                            onChange={(e) => {
                                              setFormDataValue("storage_path", e.target.value, "dataset", datasetIdx);
                                            }}
                                            placeholder="Path storage"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          <Input 
                                            value={dataset && dataset.external_aws_account_bucket}
                                            onChange={(e) => {
                                              setFormDataValue("external_aws_account_bucket", e.target.value, "dataset", datasetIdx);
                                            }}
                                            placeholder="Bucket account AWS esterno"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          <Input 
                                            value={dataset && dataset.external_aws_account.account_id}
                                            onChange={(e) => {
                                              setFormDataValue("external_aws_account", {
                                                ...dataset.external_aws_account,
                                                "account_id" : e.target.value
                                              }, "dataset", datasetIdx);
                                            }}
                                            placeholder="ID account AWS esterno"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          <Input 
                                            value={dataset && dataset.external_aws_account.account_name}
                                            onChange={(e) => {
                                              setFormDataValue("external_aws_account", {
                                                ...dataset.external_aws_account,
                                                "account_name" : e.target.value
                                              }, "dataset", datasetIdx);
                                            }}
                                            placeholder="Nome account AWS esterno"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                          <Input 
                                            value={dataset && dataset.external_aws_account.s3_access_role_name}
                                            onChange={(e) => {
                                              setFormDataValue("external_aws_account", {
                                                ...dataset.external_aws_account,
                                                "s3_access_role_name" : e.target.value
                                              }, "dataset", datasetIdx);
                                            }}
                                            placeholder="Nome ruolo di accesso S3 account AWS esterno"
                                          />
                                        </td>
                                      </>
                                    ) : datasetFormData.some((dataset) => dataset.isAwsExternal === true) ?
                                      (
                                        <>
                                          <td className="text-center">N/A</td>
                                          <td className="text-center">N/A</td>
                                          <td className="text-center">N/A</td>
                                          <td className="text-center">N/A</td>
                                          <td className="text-center">N/A</td>
                                        </>
                                      ) : <></>
                                }
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                  <button className="text-red-600 hover:text-red-900" onClick={()=> {
                                    setDatasetFormData(oldValue => {
                                      let newValue = oldValue.slice();
                                      newValue.splice(ref, 1);
                                      return newValue;
                                    })
                                  }}>
                                    Elimina<span className="sr-only"></span>
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-bold sm:pr-6 md:pr-0">
                              <button
                                className="cursor-pointer text-am-600 hover:text-am-900" 
                                onClick={()=> {
                                  setDatasetFormData(oldValue => {
                                    let newValue = oldValue.slice();
                                    newValue.push(DEFAULT_DATASET_FORM_DATA);
                                    return newValue;
                                  })
                                }}
                              >
                                AGGIUNGI
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> : <div> 
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full mb-16 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                          >
                            Tipo entità
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            Identificativo entità
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            Permessi
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        { permissionFormData && permissionFormData.map((permission, permissionIdx) => {
                          const ref = Object.freeze(permissionIdx);
                          console.log(ref);
                          return (
                            <tr key={ref}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                <Select 
                                  className="pb-2"
                                  placeholder="Tipo di entità"
                                  isSearchable
                                  value={ permission && permission.entity_type && {label: permission.entity_type,value: permission.entity_type}}
                                  options={[{value: "client", label: "Client"}, {value: "user", label: "User"}, {value: "organization", label: "Organization"}]}
                                  onChange={(e) => {
                                    if(e) setFormDataValue("entity_type", e.value, "permission", ref);
                                    else setFormDataValue("entity_type", undefined, "permission", ref);
                                  }}
                                />
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                <Input 
                                  value={permission && permission.entity_identifier}
                                  onChange={(e) => {
                                    setFormDataValue("entity_identifier", e.target.value, "permission", ref);
                                  }}
                                  placeholder="Identificativo entità"
                                />
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                <CheckboxGroup
                                  horizontal
                                  options={[
                                    { value: 1, name: "Visualizzare" },
                                    { value: 2, name: "Modificare" },
                                    { value: 4, name: "Eliminare" },
                                    { value: 8, name: "Condividere"}
                                  ]}
                                  values={checkboxGroup[ref]}
                                  onChange={(value) => {
                                    if (checkboxGroup[ref] && checkboxGroup[ref].indexOf(value) >= 0) {
                                      setCheckboxGroup(oldValue => {
                                        let newValue = oldValue.slice();
                                        newValue[ref] = [...oldValue[ref].filter((v) => v !== value)];
                                        return newValue;
                                      })

                                      //setCheckboxGroup([...checkboxGroup.filter((v) => v !== value)]);
                                    } else {
                                      setCheckboxGroup(oldValue => {
                                        let newValue = oldValue.slice();
                                        newValue[ref] = oldValue[ref] ? [...oldValue[ref], value] : [value];
                                        return newValue;
                                      })

                                      //setCheckboxGroup([...checkboxGroup, value]);
                                    }
                                  }}
                                />
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                <button className="text-red-600 hover:text-red-900" onClick={()=> {
                                  setPermissionFormData(oldValue => {
                                    let newValue = oldValue.slice();
                                    newValue.splice(ref, 1);
                                    return newValue;
                                  })
                                }}>
                                  Elimina<span className="sr-only"></span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-bold sm:pr-6 md:pr-0">
                            <button 
                              className="cursor-pointer text-am-600 hover:text-am-900" 
                              onClick={()=> {
                                setPermissionFormData(oldValue => {
                                  let newValue = oldValue.slice();
                                  newValue.push(DEFAULT_PERMISSION_FORM_DATA);
                                  return newValue;
                                })
                              }}
                            >
                              AGGIUNGI
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="flex-shrink-0 border-t border-gray-200 py-5">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="mr-6 rounded-md border border-gray-300 bg-red-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-offset-2"
                onClick={() => {
                  setModelFormData(DEFAULT_VERSION_FORM_DATA);
                  setModelArtifactData();
                  setIsNewModel(null);
                  setOpen(false);
                }}
              >
                Annulla
              </button>
              
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                onClick={() => {
                  modelLoadStep === 0 ? setModelLoadStep(0) : setModelLoadStep(oldValue => {return oldValue - 1;}); 
                }}
              >
                Indietro
              </button>

              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-am-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                onClick={() => {
                  st && modelLoadStep === st.length - 1 ? handleAddModel() : setModelLoadStep(oldValue => {return oldValue + 1;}); 
                }}
              >
                {st && modelLoadStep === st.length - 1 ? "Crea" : "Avanti"}
              </button>
            </div>
          </div>
        </div>
      </div>

    </SlideOver>
  );
}

export default ModelsHomepage;