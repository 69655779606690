import React, {useState, useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
// import AuthenticationService from "../../services/AuthenticationService";
import { useAuth0 } from "@auth0/auth0-react";

import { AuthenticationService } from "./../../services/newAuthenticationService"

const PrivateRoute = ({
  component: Component,
  scopes: pageScopes,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [ isRoleEnabled, setIsRoleEnabled ] = useState();

  

  useEffect(() => {
    (async () => {
      if(isLoading) return;

      if(!isAuthenticated) {
        setIsRoleEnabled(false);
        return;
      }

      await AuthenticationService.getAccessToken(getAccessTokenSilently);

      if(!pageScopes) {
        setIsRoleEnabled(true);
        return;
      }

      const permissions = AuthenticationService.getPermissions();
      // TODO: Refactor
      setIsRoleEnabled(pageScopes.some((r) => permissions.indexOf(r) >= 0))
    })();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, pageScopes]); 

  if(isLoading || isRoleEnabled === undefined) {
    return <p></p>
  }

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }

  if(!isRoleEnabled) {
    return <Redirect to={{pathname: "/home", state: { from: pathname } }} />
  }

  /*const scopes = AuthenticationService.getUserScopes();

  if (pageScopes && pageScopes.some((r) => scopes.indexOf(r) >= 0)) {
    return <Redirect to={{ pathname: "/home", state: { from: pathname } }} />;
  }*/

  return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
