import { useState, useContext, useMemo, useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter"
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Disclosure } from '@headlessui/react'
import Select from "react-select";
import { ArrowDownIcon as ArrowDownIconOutline, CubeIcon, PlusIcon, ArrowPathIcon, FlagIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon, LinkIcon } from '@heroicons/react/24/outline'

import Toggle from "../../../ui-components/Toggle";
import Badge from "../../../ui-components/Badge"
import SlideOver from "../../../ui-components/SlideOver";
import Button from "../../../ui-components/Button";
import Input from "../../../ui-components/Input";
import Modal from "../../../ui-components/Modal"

import { NotificationsContext } from "../../../ui-components/Notifications";
import { ModelsSettingsDataContext } from "../data";
import ModelCheckAuthorization, { MODEL_PERMISSIONS } from "./ModelCheckAuthorization";

function DatasetBadge({ datasetType }) {
  return <Badge text={datasetType} type={datasetType === "train" ? "info" : datasetType === "validation" ? "warning" : "success"}></Badge>
}

function MetricBadge({ metricType }) {
  return <Badge text={metricType} type={metricType === "mae" ? "info" : metricType === "mape" ? "warning" : "success"}></Badge>
}

function TagBadge({ tagName }) {
  return <Badge text={tagName} type={tagName === "latest" ? "success" : "warning"} classNames="truncate p-2"></Badge>
}

export default function VersionDetailSlideOver({
  open,
  setOpen,
  downloadArtifact,
  modelId,
  versionId
}) {

  const [isAddingTag, setIsAddingTag] = useState(false);
  const [tagName, setTagName] = useState("");
  const [currentDatasets, setDatasets] = useState(undefined);
  const [currentMetrics, setMetrics] = useState([]);
  const [addedDatasets, setAddedDatasets] = useState([]);
  const [addedMetrics, setAddedMetrics] = useState({});
  const [currentCodeSnippet, setCodeSnippet] = useState(``);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { push } = useContext(NotificationsContext);

  const { 
    models, 
    versions, 
    metrics,
    datasets, 

    addDataset,
    addArtifactByDatasetId,
    downloadDataset,
    removeDatasetById, 

    addTagToVersion, 
    removeTagFromVersion, 
    getMyPermissionByModelId, 
    getVersionsByModelId, 
    removeVersionById, 
    isLoading, 
    getVersionInfoById, 
    getDatasetsByVersionId, 

    addMetric, 
    addArtifactByMetricId, 
    getMetricsByVersionDatasetId, 
    downloadMetric, 
    removeMetricById 
  } = useContext(ModelsSettingsDataContext);

  const DEFAULT_METRIC_FORM_DATA = {
    "type": "",
    "description": "",
    "value": "",
    "version_id": versionId,
    "dataset_id": undefined,
    "artifact": undefined
  }

  const DEFAULT_DATASET_FORM_DATA = {
    "type": "",
    "version_id": versionId,
    "artifact" : undefined,
    "isAwsExternal": false,
    "storage_path": undefined,
    "external_aws_account_bucket": undefined,
    "external_aws_account": {
      "account_id": undefined,
      "account_name": undefined,
      "s3_access_role_name": undefined
    },
  }

  const model = useMemo(() => models && models.find(m => m.id === modelId), [modelId, models]);
  const version = useMemo(() => versions && versions.find(v => v.id === versionId), [versionId, versions]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const myPerm = useMemo(() => getMyPermissionByModelId(modelId), [modelId, models]);

  const fetchDatasets = async () => {
    const result = await getDatasetsByVersionId(versionId);
    setDatasets(result);
  }

  const fetchMetrics = async () => {
    let mergedMetrics = [];
    if(currentDatasets && versionId) {
      currentDatasets.forEach(async (dataset) => {
        let metrics = [];
        metrics = await getMetricsByVersionDatasetId(dataset.id, versionId);
        if(metrics && metrics.length !== 0) {
          metrics.forEach((metric, idx) => {
            metrics[idx] = {
              ...metric,
              "datasetId": dataset.id
            }
          });
        }

        mergedMetrics = mergedMetrics.concat(metrics);
        if(mergedMetrics !== currentMetrics) {
          setMetrics(mergedMetrics);
        }
      })
    }
  }

  useEffect(() => {
    if(versionId) {
      getVersionInfoById(versionId);
      fetchDatasets();
      fetchMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId]);

  useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDatasets, metrics]);

  useEffect(() => {
    setCodeSnippet(`
from amodelregistry_client import AModelRegistryClient

amr = AModelRegistryClient.create_m2m_authenticated_instance(
    client_id='<YOUR-CLIENT-ID>', 
    client_secret='<YOUR-CLIENT-SECRET>', 
    host='https://services.ammagamma.com/amodelregistry'
)

model = amr.get_model(version_hash='${version && version.hash}')
    `)
  }, [version]);

  useEffect(() => {
    if(addedMetrics) {
      for(const [key, value] of Object.entries(addedMetrics)) {
        if(
          (
            value.type !== "" ||
            value.description !== "" ||
            value.value !== "" ||
            value.artifact !== undefined
          ) && (value.version_id === undefined || value.dataset_id === undefined)
        ) {
          setMetricFormDataValue(key, "dataset_id", key);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedMetrics]);

  useEffect(() => {
    fetchDatasets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets]);

  const handleAddTagToVersion = (isLatest = false) => {
    isLatest ? addTagToVersion(version?.id, "latest") : addTagToVersion(version?.id, tagName);

    setIsAddingTag(false);
    setTagName("");
  }

  const handleAddMetric = async (metric) => {
    if(
      metric.type === "" ||
      metric.description === "" ||
      metric.value === "" ||
      metric.artifact === undefined ||
      metric.version_id === undefined || 
      metric.dataset_id === undefined
    ) {
      push({
        title: "Stato metrica",
        text: "Campi mancanti!"
      })
      return;
    }

    const modifiableMetric = JSON.parse(JSON.stringify(metric));
    
    let artifact = metric.artifact
    delete modifiableMetric.artifact;


    try {
      push({
        title: "Stato metrica",
        text: "Aggiungo la metrica (1/2)"
      })
      const metricCreated = await addMetric(modifiableMetric);
      const metricId = metricCreated.id

      const fileData = new FormData();
      fileData.append("metric_file", artifact[0])

      try {
        push({
          title: "Stato metrica",
          text: "Carico il file della metrica (2/2)"
        })

        await addArtifactByMetricId(metricId, fileData);

        push({
          title: "Stato metrica",
          text: "Metrica caricata correttamente!"
        })

        setAddedMetrics(oldValue => {
          let newValue = {...oldValue}
          delete newValue[metric.dataset_id];

          return newValue;
        })
      } catch (error) {
        push({
          title: "Impossibile caricare l'artefatto della metrica",
          text: "L'artefatto sembra essere giÃ  essere presente!"
        })
      }
    } catch (error) {
      push({
        title: "Impossibile creare la metrica",
        text: error.text
      })
    }
  }

  const handleAddDatasets = async () => {
    if(addedDatasets.length === 0) return;

    addedDatasets.forEach(async (dataset) => {
      if(
        dataset.type === undefined ||
        dataset.version_id === undefined ||
        (dataset.isAwsExternal === false  && dataset.artifact === undefined) ||
        dataset.isAwsExternal &&
        (
          dataset.storage_path === undefined ||
          dataset.external_aws_account_bucket === undefined ||
          dataset.external_aws_account.account_id === undefined ||
          dataset.external_aws_account.account_name === undefined ||
          dataset.external_aws_account.s3_access_role_name === undefined
        )
      ) {
        push({
          title: "Stato dataset",
          text: "Campi mancanti!"
        })
        return;
      }

      const modifiableDataset = JSON.parse(JSON.stringify(dataset));
      const isAwsExternal = modifiableDataset.isAwsExternal;
      let artifact;

      if(dataset.artifact) {
        artifact = dataset.artifact;
      }

      if(!isAwsExternal) {
        delete modifiableDataset.external_aws_account
      }
      delete modifiableDataset.artifact;
      delete modifiableDataset.isAwsExternal;
      
      try {
        // 1. Creating model
        push({
          title: "Stato dataset",
          text: "Aggiungo il dataset (1/2)"
        })
        const datasetCreated = await addDataset(modifiableDataset);

        const datasetId = datasetCreated.id;
        if(!isAwsExternal) {
          push({
            title: "Stato dataset",
            text: "Carico il file del dataset (2/2)"
          })

          const fileData = new FormData();
          fileData.append("dataset_file", artifact[0])
          console.log("DATASET FILE DATA", fileData.get("dataset_file"));

          try {
            await addArtifactByDatasetId(datasetId, fileData);

            push({
              title: "Stato dataset",
              text: "Dataset aggiunto con successo!"
            })
          } catch(err) {
            push({
              title: "Impossibile caricare l'artefatto",
              text: "L'artefatto sembra essere già essere presente!"
            })
            console.warn("Error during artifact creation ", err);
          }
        } else {
          push({
            title: "Stato dataset",
            text: "Dataset aggiunto con successo!"
          })
        }
      } catch(error) {
        console.error(error);
        push({
          title: "Impossibile creare il dataset",
          text: error
        })
      }
    })
  }

  const setMetricFormDataValue = (id, key, value) => {
    setAddedMetrics(oldValue => {
      let newValue = {
        ...oldValue,
        [id]: {
          ...oldValue[id],
          [key]: value
        }
      }

      return newValue;
    })
  }

  const setDatasetFormDataValue = (id, key, value) => {
    setAddedDatasets(oldValue => {
      let newValue = [...oldValue];
      newValue[id][key] = value;

      return newValue;
    })
  }

  const Header = () => {
    if(!model || !version) return <></>;
    return (
      <div className="space-y-1">
        <div className="flex items-center space-x-3">
          <CubeIcon className="w-8 h-8"/>
          <h3 className="truncate text-2xl font-bold text-gray-900">{model.name}:<span className="font-extralight">{version.hash ? version.hash.substring(0, 6) : ""}</span></h3>
          <Button 
            styleType="white" 
            submitting={isLoading}
            onClick={() => {
              getVersionsByModelId(model.id)
            }}
          >
            <ArrowPathIcon className="h-6 w-6 " aria-hidden="true"/>
          </Button>
        </div>
        <p className="">
          {version.description}
        </p>
        <span className="text-sm text-gray-500">Creato il: {new Date(version.created_at).toLocaleDateString()}</span> &nbsp;
        <span className="text-sm text-gray-500">Aggiornato il: {new Date(version.updated_at).toLocaleDateString()}</span>
        
        {
          (version.bitbucket_commit !== "" && version.bitbucket_commit !== undefined) && 
            <div className="flex flex-row items-center text-sm gap-1 mb-1 text-gray-500">
              Commit:&nbsp;
              <a
                href={`https://bitbucket.org/energyway/${model && model.bitbucket_repository}/commits/${version.bitbucket_commit}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
                style={{"color": "var(--c-light-blue)"}}
              >
                <LinkIcon className="w-4 h-4 mr-0.5" />
                <span className="text-sm">{version.bitbucket_commit}</span>
              </a>
            </div>
        }
      </div>
    );
  }
  
  //console.log(addedMetrics);
  //console.log("METRICHE CORRENTI GLOBALI", currentMetrics);
  //console.log(currentDatasets)

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={<Header />}
    >
      <div className="px-4">
        <button
          type="button"
          className="inline-flex items-center rounded-full border border-transparent bg-am-600 px-2 mb-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
          onClick={ async () => {
            handleAddTagToVersion(true);
          }}
        >
          <FlagIcon className="h-5 w-5" aria-hidden="true"/>
          <span className="p-1">Imposta come Latest</span>
        </button>

        <button
          type="button"
          className="inline-flex items-center rounded-full border border-transparent bg-green-600 px-2 mb-2 ml-2 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          onClick={ async () => {
            const res = await downloadArtifact(version.hash);
            const url = window.URL.createObjectURL(res)
            const link = document.createElement("a");
            link.href = url;
            link.className = "hidden";
            link.setAttribute("download", model.name + "_" + version.tags[0] + ".zip");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          <ArrowDownIconOutline className="h-5 w-5" aria-hidden="true"/>
          <span className="p-1">Download</span>
        </button>

        <div className="mt-5">
          <h3 className="font-bold text-xl text-gray-900">Tags</h3>
          <ul className="mt-2 divide-y divide-gray-200 border-b border-gray-200">
            {version && version.tags.map((tag) => 
              <li key={tag} className="flex items-center justify-between py-3">
                <TagBadge tagName={tag} />
                  <ModelCheckAuthorization
                    permission={myPerm}
                    check={MODEL_PERMISSIONS.UPDATE}
                  >
                    <button
                      onClick={() => removeTagFromVersion(version?.id, tag) }
                      type="button"
                      className="ml-6 rounded-md bg-white text-sm font-medium text-am-600 hover:text-am-500 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                    >
                      Rimuovi
                    </button>
                  </ModelCheckAuthorization>

              </li>
            )}

            {isAddingTag ?
              <li className="flex items-center justify-between py-3">
              <Input 
                placeholder="Nome tag"
                value={tagName}
                onChange={(e) => {
                  setTagName(e.target.value)
                }}
              />

              <button
                onClick={() => handleAddTagToVersion()}
                type="button"
                className="ml-6 rounded-md bg-white text-sm font-medium text-am-600 hover:text-am-500 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              >
                Aggiungi
              </button>
            </li> :

            <ModelCheckAuthorization
              permission={myPerm}
              check={MODEL_PERMISSIONS.UPDATE}
            >
              <li className="flex items-center justify-between py-2">
                <button
                  onClick={() => setIsAddingTag(true)}
                  type="button"
                  className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-am-500"
                >
                  <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-am-600 group-hover:text-am-500">
                    Aggiungi
                  </span>
                </button>
              </li>
            </ModelCheckAuthorization>
            }
          </ul>
        </div>

        <div className="mt-5">
          <div className="flex flex-row items-center justify-between">
            <h3 className="font-bold text-xl text-gray-900">Datasets</h3>

            <button
              type="button"
              className="inline-flex items-center rounded-full border border-transparent bg-am-600 px-2 ml-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={ async () => {
                if(addedDatasets.length === 0) {
                  setAddedDatasets(oldValue => {
                    let newValue = [...oldValue];
                    newValue.push(DEFAULT_DATASET_FORM_DATA);
                    
                    return newValue;
                  });
                } else {
                  await handleAddDatasets()
                  setAddedDatasets([]);
                }
              }}
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true"/>
              {
                addedDatasets.length === 0 ? <span className="p-1 text-sm">Aggiungi dataset</span> : <span className="p-1 text-sm">Conferma aggiunta {addedDatasets.length} dataset</span>
              }  
              </button>
          </div>
          
          {
            addedDatasets.length !== 0 ? <div>
              <div className="mt-2 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full mb-16 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                          >
                            Tipo
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            File dataset
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            AWS esterno?
                          </th>
                          {

                            addedDatasets.some((dataset) => dataset.isAwsExternal === true) ? (
                              <>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Path storage
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Bucket account AWS esterno 
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  ID account AWS esterno
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Nome account AWS esterno
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                  Nome ruolo di accesso S3 account AWS esterno
                                </th>
                              </>
                            ) : <></>
                          }
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 ">
                        { addedDatasets && addedDatasets.map((dataset, datasetIdx) => {
                          const ref = Object.freeze(datasetIdx);
                          console.log(ref);
                          return (
                            <tr key={ref}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                <Select
                                  className="pb-2"
                                  placeholder="Tipo di dataset"
                                  options={[{value: "train", label : "train"}, {value: "validation", label : "validation"}, {value: "test", label : "test"}]}
                                  isSearchable
                                  isClearable
                                  value={dataset && dataset.type && {label: dataset.type, value: dataset.type}}
                                  onChange={(e) => {
                                    console.log("SELECT REF", ref)
                                    if(e) setDatasetFormDataValue(ref, "type", e.value);
                                    else setDatasetFormDataValue(ref, "type", undefined);
                                  }}
                                />
                              </td>
                              {
                                dataset.isAwsExternal ? <td className="text-center">N/A</td>  : 
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  <input 
                                    type="file"
                                    multiple={false}
                                    onChange={(e) => {
                                      let newList = [];
                                      Object.keys(e.target.files).forEach((key) => {
                                        newList.push(e.target.files[key]);
                                      });

                                      console.log("FILE REF", ref)

                                      setDatasetFormDataValue(ref, "artifact", newList)
                                    }}
                                  />
                                  {/*<FilePicker 
                                    className="pb-2"
                                    fileChosen={dataset.artifact}
                                    setFileChosen={(file, idx = ref) => {
                                      console.log("FILE REF", idx)

                                      setDatasetFormDataValue("artifact", file, "dataset", idx)
                                    }}
                                  />
                                  */}
                                </td>
                              }
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                <Toggle 
                                  checked={dataset.isAwsExternal}
                                  onChange={() => {
                                    console.log("TOGGLE REF", ref)

                                    setDatasetFormDataValue(ref, "isAwsExternal", !dataset.isAwsExternal);
                                    if(!dataset.isAwsExternal === true) {
                                      setDatasetFormDataValue(ref, "artifact", undefined)
                                    } else {
                                      setDatasetFormDataValue(ref, "storage_path", undefined)
                                      setDatasetFormDataValue(ref, "external_aws_account_bucket", undefined)
                                      setDatasetFormDataValue(ref, "external_aws_account", {
                                        "account_id": undefined,
                                        "account_name": undefined,
                                        "s3_access_role_name": undefined
                                      })
                                    }
                                  }}
                                />
                              </td>
                              {
                                dataset.isAwsExternal ? 
                                  ( 
                                    <>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Input 
                                          value={dataset && dataset.storage_path}
                                          onChange={(e) => {
                                            setDatasetFormDataValue(ref, "storage_path", e.target.value);
                                          }}
                                          placeholder="Path storage"
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Input 
                                          value={dataset && dataset.external_aws_account_bucket}
                                          onChange={(e) => {
                                            setDatasetFormDataValue(ref, "external_aws_account_bucket", e.target.value);
                                          }}
                                          placeholder="Bucket account AWS esterno"
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Input 
                                          value={dataset && dataset.external_aws_account.account_id}
                                          onChange={(e) => {
                                            setDatasetFormDataValue(ref, "external_aws_account", {
                                              ...dataset.external_aws_account,
                                              "account_id" : e.target.value
                                            });
                                          }}
                                          placeholder="ID account AWS esterno"
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Input 
                                          value={dataset && dataset.external_aws_account.account_name}
                                          onChange={(e) => {
                                            setDatasetFormDataValue(ref, "external_aws_account", {
                                              ...dataset.external_aws_account,
                                              "account_name" : e.target.value
                                            });
                                          }}
                                          placeholder="Nome account AWS esterno"
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Input 
                                          value={dataset && dataset.external_aws_account.s3_access_role_name}
                                          onChange={(e) => {
                                            setDatasetFormDataValue(ref, "external_aws_account", {
                                              ...dataset.external_aws_account,
                                              "s3_access_role_name" : e.target.value
                                            });
                                          }}
                                          placeholder="Nome ruolo di accesso S3 account AWS esterno"
                                        />
                                      </td>
                                    </>
                                  ) : addedDatasets.some((dataset) => dataset.isAwsExternal === true) ?
                                    (
                                      <>
                                        <td className="text-center">N/A</td>
                                        <td className="text-center">N/A</td>
                                        <td className="text-center">N/A</td>
                                        <td className="text-center">N/A</td>
                                        <td className="text-center">N/A</td>
                                      </>
                                    ) : <></>
                              }
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                <button className="text-red-600 hover:text-red-900" onClick={()=> {
                                  setAddedDatasets(oldValue => {
                                    let newValue = oldValue.slice();
                                    newValue.splice(ref, 1);
                                    return newValue;
                                  })
                                }}>
                                  Elimina<span className="sr-only"></span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-bold sm:pr-6 md:pr-0">
                            <button
                              className="cursor-pointer font-bold text-am-600 hover:text-am-900" 
                              onClick={()=> {
                                setAddedDatasets(oldValue => {
                                  let newValue = oldValue.slice();
                                  newValue.push(DEFAULT_DATASET_FORM_DATA);
                                  return newValue;
                                })
                              }}
                            >
                              AGGIUNGI
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> : <></>
          }
          
          <dl className="mt-2 divide-y divide-gray-200 border-b border-gray-200">
            {currentDatasets && currentDatasets.map((dataset) => 
              ( 
                <Disclosure as="div" key={dataset.id} className="py-4">
                  {({open}) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <div className="flex items-center space-x-3">
                            <span>{dataset.id}</span>
                            <DatasetBadge datasetType={dataset.type} />
                          </div>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div key={dataset.id}>
                          <div className="flex items-center py-3">
                            {
                              dataset.storage_type === "internal" ? 
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-full border border-transparent bg-green-600 px-2 mb-2 ml-2 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                  onClick={ async () => {
                                    const res = await downloadDataset(dataset.id);
                                      const url = window.URL.createObjectURL(res)
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.className = "hidden";
                                      link.setAttribute("download", dataset.id + "_" + version.id);
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                  }}
                                >
                                  <ArrowDownIconOutline className="h-5 w-5" aria-hidden="true"/>
                                  <span className="p-1 text-sm">Download dataset {dataset.id}</span>
                                </button>  : 
                                <button
                                type="button"
                                className="inline-flex items-center rounded-full border border-transparent bg-gray-600 px-2 mb-2 ml-2 text-white shadow-sm"
                              >
                                <span className="p-1 text-sm">Dataset Esterno</span>
                              </button>
                            }

                            <button
                              type="button"
                              className="inline-flex items-center rounded-full border border-transparent bg-am-600 px-2 mb-2 ml-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                              onClick={ async () => {
                                if(!addedMetrics[dataset.id]) {
                                  setAddedMetrics(oldValue => {
                                    let newValue = {
                                      ...oldValue,
                                      [dataset.id]: DEFAULT_METRIC_FORM_DATA
                                    };

                                    return newValue;
                                  });
                                } else handleAddMetric(addedMetrics[dataset.id])
                              }}
                            >
                              <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                              {
                                !addedMetrics[dataset.id] ? <span className="p-1 text-sm">Aggiungi metrica</span> : <span className="p-1 text-sm">Conferma aggiunta metrica</span>
                              }
                            </button>

                            <button
                              type="button"
                              className="inline-flex items-center rounded-full border border-transparent bg-red-600 px-2 mb-2 ml-2 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              onClick={ async () => {
                                removeDatasetById(dataset.id);
                              }}
                            >
                              <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                              <span className="p-1 text-sm">Elimina dataset</span>
                            </button>
                          </div>

                          {
                            addedMetrics[dataset.id] !== undefined ? <div>
                              <h4 className="font-bold text-lg text-gray-900">Aggiungi una metrica</h4>
                              <div className="mt-2 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="inline-block min-w-full mb-16 align-middle md:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                          >
                                            Tipo
                                          </th>
                                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Descrizione
                                          </th>
                                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            Valore
                                          </th>
                                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                            File metrica
                                          </th>
                                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                                            <span className="sr-only">Edit</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200">
                                        <tr key={dataset.id}>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            <Select
                                              className="pb-2"
                                              placeholder="Tipo di metrica"
                                              options={[{value: "mae", label : "mae"}, {value: "mape", label : "mape"}, {value: "mre", label : "mre"}]}
                                              isSearchable
                                              isClearable
                                              value={addedMetrics && addedMetrics[dataset.id].type && {label: addedMetrics[dataset.id].type, value: addedMetrics[dataset.id].type}}
                                              onChange={(e) => {
                                                setMetricFormDataValue(dataset.id, "type", e.value)
                                              }}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            <Input 
                                              value={addedMetrics && addedMetrics[dataset.id].description}
                                              onChange={(e) => {
                                                setMetricFormDataValue(dataset.id, "description", e.target.value)
                                              }}
                                              placeholder="Descrizione metrica"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            <Input 
                                              value={addedMetrics && addedMetrics[dataset.id].value}
                                              onChange={(e) => {
                                                setMetricFormDataValue(dataset.id, "value", e.target.value)
                                              }}
                                              placeholder="Valore metrica"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                            <input
                                              type="file"
                                              multiple={false}
                                              onChange={(e) => {
                                                let newList = [];
                                                Object.keys(e.target.files).forEach((key) => {
                                                  newList.push(e.target.files[key]);
                                                });

                                                setMetricFormDataValue(dataset.id, "artifact", newList);
                                              }}
                                            />
                                          </td>
                                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                            <button className="text-red-600 hover:text-red-900" onClick={()=> {
                                              setAddedMetrics(oldValue => {
                                                let newValue = {...oldValue}
                                                delete newValue[dataset.id];
                                      
                                                return newValue;
                                              })
                                            }}>
                                              Elimina<span className="sr-only"></span>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div> : <></>
                          }

                          {
                            currentMetrics.filter((metric) => metric.datasetId === dataset.id).length !== 0 ? 
                              <h4 className="font-bold text-lg text-gray-900 pl-3">Metriche dataset {dataset.id}</h4>
                              :
                              <span className="font-bold text-base text-gray-500 pl-3">Nessuna metrica disponibile</span>
                          }
                          <div className="m-auto grid grid-cols-3 gap-10 pl-3">
                            {
                              currentMetrics.filter((metric) => metric.datasetId === dataset.id).map((metric) => (
                                <div className="shadow-xl rounded-lg">
                                  <div className="p-5">
                                    <div className="flex flex-row items-center justify-between">
                                      <div className="flex flex-row items-center space-x-2">
                                        <span>{metric.id}</span>
                                        <MetricBadge metricType={metric.type} />
                                      </div>

                                      <div className="flex flex-row items-center">
                                        <button
                                          type="button"
                                          className="inline-flex items-center rounded-full border border-transparent bg-green-600 px-1 py-1 mb-2 ml-2 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                          onClick={ async () => {
                                            const res = await downloadMetric(metric.id);
                                            const url = window.URL.createObjectURL(res)
                                            const link = document.createElement("a");
                                            link.href = url;
                                            link.className = "hidden";
                                            link.setAttribute("download", metric.id + "_" + dataset.id + "_" + version.id);
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                          }}
                                        >
                                          <ArrowDownIconOutline className="h-5 w-5" aria-hidden="true"/>
                                        </button>

                                        <button
                                          type="button"
                                          className="inline-flex items-center rounded-full border border-transparent bg-red-600 px-1 py-1 mb-2 ml-2 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                          onClick={ async () => {
                                            removeMetricById(metric.id)
                                          }}
                                        >
                                          <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                      </div>
                                    </div>

                                    <span>{metric.description}</span>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
            )}
          </dl>
        </div>

        <div className="mt-5">
          <h3 className="font-bold text-xl text-gray-900">Snippet</h3>
          <div className="mt-2 bg-black text-white rounded-xl ">
          <SyntaxHighlighter language="python" style={docco}>
            {currentCodeSnippet && currentCodeSnippet}
          </SyntaxHighlighter>
          </div>
        </div>
        
        <ModelCheckAuthorization
          permission={myPerm}
          check={MODEL_PERMISSIONS.DELETE}
        >
          <div className="mt-5 bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Elimina questa versione</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Una volta eliminata, perderai TUTTI i dati associati</p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  Elimina
                </button>
              </div>
            </div>
          </div>
        </ModelCheckAuthorization>
      </div>

      <Modal
        opened={openDeleteModal}
        title={"Conferma eliminazione"}
        description={"Sei sicuro di voler eliminare la versione? Perderai TUTTI I DATI ASSOCIATI"}
        onConfirm={() => {
          setOpenDeleteModal(false);
          removeVersionById(version.id);
          setOpen(false);
        }}
        onDismiss={() => {
          setOpenDeleteModal(false);
        }}
        onExit={() => {
          setOpenDeleteModal(false);
        }}
        confirmText = "ELIMINA"
        dismissText = "ANNULLA"
      >
      </Modal>
    </SlideOver>
  );
}