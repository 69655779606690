import "./App.css";
import React from "react";
import {IntlProvider} from "react-intl";
import {Route, Redirect, Switch, useLocation} from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import OAuth2 from "./ui-components/pages/OAuth2";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import filterRoutesByScopes from "./utils/filterRoutesByScopes";
import locale from "./locale";
import { useAuth0 } from "@auth0/auth0-react";

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    // Define Log Variable
    let logging = {};

    const loggingInfo = window.localStorage.logged_user ? JSON.parse(window.localStorage.logged_user) : null;


    // Log information on page change
    // PAGE
    logging['page'] = location.pathname;
    // DEVICE INFO
    let device = window.navigator.userAgentData;
    let browser = device?.brands?.length > 0 ? device.brands[2] : null;
    logging['browser'] = browser?.brand;
    logging['browser_version'] = parseFloat(browser?.version);
    logging['mobile'] = device?.mobile;
    logging['platform'] = device?.platform;
    logging['user'] = loggingInfo?.email;

    // POST
    /*const auditNavigationPost = async () => apiRequest.post(
      "/audit/navigation",
      logging,
      {publicRequest: false, audit: true}
    )

    auditNavigationPost()*/

  }, [location]);
};

function App() {
  usePageViews();

  const {error} = useAuth0();


  if(error) {
    return error.message;
  }

  return (
    <ErrorBoundary>
      <IntlProvider
        messages={locale[localStorage.getItem("lang")]}
        locale={localStorage.getItem("lang")}
        defaultLocale="it"
      >
        <Notifications>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={() => <Redirect to={{pathname: "/models"}}/>}
            />
            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/oauth2" component={OAuth2}/>
            <Route exact path="/registration/:token" component={Registration}/>

            <PrivateRoute
              path={[
                ...filterRoutesByScopes(menu)
                  .reduce(
                    (acc, route) => [
                      ...acc,
                      ...(route.dropdown ? route.dropdown : [route]),
                    ],
                    []
                  )
                  .map((route) => route.href),
                "/profile",
              ]}
              component={Dashboard}
            />
            <Route path="*" component={NotFound}/>
          </Switch>
        </Notifications>
      </IntlProvider>
    </ErrorBoundary>
  ) 
}

export default App;
