import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import apiRequest from "../apiRequest";
import {parseJwt} from "../../utils";

class AuthenticationService {
    static _accessToken = undefined;
    static _permissions = undefined;

    static setAccessToken(token) {
        AuthenticationService._accessToken = token;

        const parsedToken = parseJwt(token);
        if(!parsedToken) {
            AuthenticationService._permissions = [];
        } else {
            AuthenticationService._permissions = parsedToken.permissions;
        }
    }

    static async getAccessToken(getAccessTokenSilently) {
        if(AuthenticationService._accessToken === undefined) {
            if(getAccessTokenSilently === undefined) {
                console.error("You are trying to access access_token [AuthenticationService] but it is not set");
                throw new Error("Access Token in Authentication Service is not set!");
            }
                        
            const token = await getAccessTokenSilently();
            AuthenticationService.setAccessToken(token);

            return token;
        }

        return AuthenticationService._accessToken;
    }

    static getPermissions() {
        if(AuthenticationService._permissions === undefined) {
            console.error("You are trying to access Permissions [AuthenticationService] but it is not set. Please set an access_token with [AuthenticationService.getAccessToken]");
            throw new Error("Access Token in Authentication Service is not set!");
        }

        return AuthenticationService._permissions;
    }
}

function useApi(path, params) {
    const { getAccessTokenSilently } = useAuth0();

    const [userRequest, setUserRequest] = useState({
        loading: true,
        error: undefined,
        data: undefined,
    })

    const setter = useCallback((obj) => {
        setUserRequest({
            ...userRequest,
            ...obj
        })
    }, [userRequest])

    const fetchData = useCallback(async () => {
        setter({ loading: true })
        try {
            const data = await apiRequest.get(path, {
                params
            });
            setter({
                loading: false,
                data,
            })
        } catch(err) {
            setter({
                loading: false,
                error: err
            })
        }
    }, [params, path, setter])

    useEffect(() => {
        (async () => {
            await AuthenticationService.getAccessToken(getAccessTokenSilently);
            await fetchData(path);
        })();
    }, [path, fetchData, getAccessTokenSilently]);

    const { data, error, loading } = userRequest;

    return [
        data, 
        loading,
        error,
        fetchData
    ];
}

export {
    AuthenticationService,
    useApi
}