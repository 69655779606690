import React, {createContext, useState} from "react";
import apiRequest from "../../services/apiRequest";
import { useAuth0 } from '@auth0/auth0-react';

import _ from "lodash";

const metricFetcherActions = {
  GET_ALL: "GET_ALL",
  GET_BY_ID: "GET_BY_ID",
  GET_BY_VERSION_DATASET_ID: "GET_BY_VERSION_DATASET_ID"
}
async function metricFetcher(action) {
  switch(action.type) {
    case metricFetcherActions.GET_ALL:
      return await apiRequest.get("/metric/");
    case metricFetcherActions.GET_BY_ID:
      return await apiRequest.get(`/metric/${action.id}`);
    case metricFetcherActions.GET_BY_VERSION_DATASET_ID:
      return await apiRequest.get(`/dataset/${action.datasetId}/metrics/${action.versionId}`);
    default:
      return {}
  }
}

const datasetFetcherActions = {
  GET_ALL: "GET_ALL",
  GET_BY_ID: "GET_BY_ID",
  GET_BY_VERSION_ID: "GET_BY_VERSION_ID"
}
async function datasetFetcher(action) {
  switch(action.type) {
    case datasetFetcherActions.GET_ALL:
      return await apiRequest.get("/dataset/");
    case datasetFetcherActions.GET_BY_ID:
      return await apiRequest.get(`/dataset/${action.id}`);
    case datasetFetcherActions.GET_BY_VERSION_ID:
      return await apiRequest.get(`/version/${action.id}/datasets`);
    default:
      return {}
  }
}

const modelFetcherActions = {
  GET_ALL: "GET_ALL",
  GET_BY_ID: "GET_BY_ID"
}
async function modelFetcher(action) {
  switch(action.type) {
    case modelFetcherActions.GET_ALL:
      return await apiRequest.get("/opmodel/");
    case modelFetcherActions.GET_BY_ID:
      const model = await apiRequest.get(`/opmodel/${action.id}`);
      const permissions = await apiRequest.get(`/opmodel/${action.id}/authorization`);
      return {
        ...model,
        permissions
      }
    default:
      return {}
  }
}


const versionFetcherActions = {
  GET_ALL: "GET_ALL",
  GET_ALL_BY_MODEL_ID: "GET_ALL_BY_MODEL_ID",
  GET_BY_ID: "GET_BY_ID"
}
async function versionFetcher(action) {
  switch(action.type) {
    case versionFetcherActions.GET_ALL:
      return await apiRequest.get("/opmodel/");
    case versionFetcherActions.GET_ALL_BY_MODEL_ID:
      return await apiRequest.get(`/opmodel/${action.id}/versions`);
    case versionFetcherActions.GET_BY_ID:
      return await apiRequest.get(`/version/${action.id}/info`)
    default:
      return {}
  }
}


const ModelsSettingsDataContext = createContext();

const ModelSettingsDataProvider = ({ children }) => {

  const { user } = useAuth0();
  const { email } = user;

  const [models, setModels] = useState([]); // [{}, {}]
  const [versions, setVersions] = useState([]); // [{"opmodel_id":"1", versions: []] => {"1": []} => [{...version, opmodel_id: ""}]
  const [datasets, setDatasets] = useState([]);
  const [metrics, setMetrics] = useState([]);

  const [isModelsLoading, setIsModelsLoading] = useState(false);
  const [isVersionsLoading, setIsVersionsLoading] = useState(false);
  const [isDatasetsLoading, setIsDatasetsLoading] = useState(false);
  const [isMetricsLoading, setIsMetricsLoading] = useState(false);

  const getMetrics = async () => {
    setIsMetricsLoading(true);
    const metrics = await metricFetcher({
      type: "GET_ALL"
    });

    console.log(metrics)

    setMetrics(oldMetrics => {
      const mergedMetrics = _.unionBy(oldMetrics, metrics, "id");
      return mergedMetrics;
    })
    setIsMetricsLoading(false);
  }

  const addMetric = async (formData) => {
    setIsMetricsLoading(true);
    try {
      const data = await apiRequest.post(`/metric/`, formData);

      setMetrics(oldMetrics => {
        if(!oldMetrics) return [data];

        const mergedMetrics = _.unionBy([data], oldMetrics, "id");
        return mergedMetrics;
      })

      setIsMetricsLoading(false);
      return data;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const getMetricsByVersionDatasetId = async (datasetId, versionId) => {
    //setIsMetricsLoading(true);
    const metrics = await metricFetcher({
      type: "GET_BY_VERSION_DATASET_ID",
      datasetId: datasetId,
      versionId: versionId
    });

    let oldMetrics = metrics && metrics.filter((metric) => metric.dataset_id === datasetId && metric.version_id === versionId);

    //setIsMetricsLoading(false);
    
    return _.unionBy(oldMetrics, metrics, "id");
  }

  const addArtifactByMetricId = async (metricId, artifact) => {
    try {
      await apiRequest.put(`/metric/${metricId}`, artifact);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const downloadMetric = async (id) => {
    try {
      const data = await apiRequest.get(`/metric/${id}/download`, {
        responseType: "blob"
      });
      return data;
    } catch (error) {
      console.error(error)
      throw error;
    }
  } 

  const removeMetricById = async (id) => {
    try {
      await apiRequest.delete(`/metric/${id}`);

      setMetrics(oldMetrics => {
        let newMetrics = [...oldMetrics];       
        newMetrics.splice(
          newMetrics.indexOf(newMetrics.find((metric) => metric.id === id)), 
          1
        );
        return newMetrics;
      });
    } catch(error) {
      console.error(error);
      throw error;
    }
  }

	const getDatasets = async () => {
    const datasets = await datasetFetcher({
      type: "GET_ALL"
    });

    console.log(datasets)

    setDatasets(oldDatasets => {
      const mergedDatasets = _.unionBy(oldDatasets, datasets, "id");
      return mergedDatasets;
    })
  }

  const getDatasetById = async (id) => {
    let dataset = datasets && datasets.find(model => model.id === id);
    setIsDatasetsLoading(true);
    dataset = await datasetFetcher({ type: "GET_BY_ID", id: id });
    setDatasets(oldDatasets => _.unionBy([dataset], oldDatasets, "id"));
    setIsDatasetsLoading(false);
    // }
    return dataset;
  }

  const getDatasetsByVersionId = async (id) => {
    let currentDatasets = datasets && datasets.filter(dataset => dataset.id === id);
    //setIsDatasetsLoading(true);
    let newDatasets = await datasetFetcher({ type: "GET_BY_VERSION_ID", id });
    //setDatasets(oldDatasets => _.unionBy([dataset], oldDatasets, "id"));
    //setIsDatasetsLoading(false);
    
    return _.unionBy(currentDatasets, newDatasets, "id");
  }

  const addDataset = async (formData) => {
    try {
      const data = await apiRequest.post(`/dataset/`, formData);

      setDatasets(oldDatasets => {
        if(!oldDatasets) return [data];

        const mergedDatasets = _.unionBy([data], oldDatasets, "id");
        return mergedDatasets;
      })

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const addArtifactByDatasetId = async (datasetId, artifact) => {
    try {
      await apiRequest.put(`/dataset/${datasetId}`, artifact);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const downloadDataset = async (id) => {
    try {
      const data = await apiRequest.get(`/dataset/${id}/download`, {
        responseType: "blob"
      });
      return data;
    } catch (error) {
      console.error(error)
      throw error;
    }
  }

  const removeDatasetById = async (id) => {
    try {
      await apiRequest.delete(`/dataset/${id}`);

      setDatasets(oldDatasets => {     
        oldDatasets.splice(
          oldDatasets.indexOf(oldDatasets.find((dataset) => dataset.id === id)), 
          1
        );

        let newDatasets = [...oldDatasets];  
        return newDatasets;
      });
    } catch(error) {
      console.error(error);
      throw error;
    }
  }

  const handleSetVersions = (versions) => {
    setVersions(oldVersions => {
      let allVersions = _.unionBy(versions, oldVersions, "id");

      return allVersions;
    });
  }

  const getModels = async () => {
    //setIsLoading(true);
    const models = await modelFetcher({
      type: "GET_ALL"
    });

    setModels(oldModels => {
      const mergedModels = _.unionBy(oldModels, models, "id");
      return mergedModels;
    })
  }

  const getModelById = async (id) => {
    let model = models && models.find(model => model.id === id);
    // if(!model) {
    setIsModelsLoading(true);
    model = await modelFetcher({ type: "GET_BY_ID", id });
    setModels(oldModels => _.unionBy([model], oldModels, "id"));
    setIsModelsLoading(false);
    // }
    return model;
  }

  const updateModelById = async (id, updatedData) => {
    let oldModel = models && models.find(model => model.id === id);

    setIsModelsLoading(true);

    const updatedModel = await apiRequest.put(`/opmodel/${id}`, updatedData);

    setModels(oldModels => {
      const oldModelIndex = oldModels.indexOf(oldModel);
      oldModels[oldModelIndex] = updatedModel;
      return oldModels;
    });

    setIsModelsLoading(false);
    return updatedModel;
  }

  const getVersionsByModelId = async (id) => {
    setIsVersionsLoading(true);
    const versions = await versionFetcher({ type: versionFetcherActions.GET_ALL_BY_MODEL_ID, id });
    const versionsWithOpmodelId = versions.map((d) => {return { ...d, opmodelId: id }})
    setVersions(oldVersions => {
      let allVersions = _.unionBy(oldVersions, versionsWithOpmodelId, "id");

      return allVersions;
    });
    setIsVersionsLoading(false);
    return versions;
  }

  const getMyPermissionByModelId = (id) => {
    let model = models && models.find((model) => model.id === id);
    
    if(!model?.permissions) return;
    
    const myPermission = model.permissions.find(permission => permission.entity_identifier === email);
    return myPermission; 
  }

  const getVersionInfoById = async (id) => {
    const version = await versionFetcher({
      type: versionFetcherActions.GET_BY_ID,
      id
    })

    version.tags = version.tags.map(tag => tag.model_tag);

    version.opmodelId = version.opmodel.id.toString();
    delete version.opmodel;

    handleSetVersions([version]);
    return version;
  }

   // "OLD" functions

  const fetchModelById = async (id) => {
    //setIsLoading(true)
    try {
      const data = await apiRequest.get(`/opmodel/${id}`);

      let newModels = [data];
      if(!models) {
        setModels(newModels);
      } else {
        newModels = [...models, data]
        setModels(newModels);
      }
      //setIsLoading(false);

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const addModel = async (formData) => {
    try {
      const data = await apiRequest.post(`/opmodel/`, formData);

      setModels(oldModels => {
        if(!oldModels) return [data];

        const mergedModels = _.unionBy([data], oldModels, "id");
        return mergedModels;
      })

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const removeModelById = async (opmodelid) => {
    //setIsLoading(true);
    try {
      await apiRequest.delete(`/opmodel/${opmodelid}`);

      let newModels = [];
      setModels(oldModels => {
        newModels = oldModels ? oldModels.map(model => {
          delete model.permissions;
          return model;
        }) : [];
                
        newModels.splice(
          newModels.indexOf(newModels.find((model) => model.id === opmodelid)), 
          1
        );
        return newModels;
      });

      return newModels;
      //setIsLoading(false)
    } catch(error) {
      console.error(error);
      throw error;
    }
  }

  const addModelAuthorization = async (opmodelid, formData) => {
    try {
      const addedPermission = await apiRequest.post(`/opmodel/${opmodelid}/authorization`, formData);

      const model = models && models.find((model) => model.id === opmodelid);
      if(model) {
        model.permissions.push(addedPermission);
        if(!models) {
          setModels([model]);
        } else {
          setModels((oldModels) => {
            const mergedModels = _.unionBy(oldModels, model, "id");
            return mergedModels;
          });
        }
      }
    } catch(error) {
      console.error(error);
      throw error;
    }
  }

  const removeModelAuthorization = async (opmodel_id, entity_id) => {
    if(entity_id === email) return;

    try {
      await apiRequest.delete(`/opmodel/${opmodel_id}/authorization/${entity_id}`);

      const model = models && models.find((model) => model.id === opmodel_id);
      if(model) {
        model.permissions.splice(
          model.permissions.indexOf(
            model.permissions.find((permission) => permission.entity_identifier === entity_id)
          ), 1
        );
        setModels((oldModels) => {
          return _.unionBy(
            [model],
            oldModels,
            "id"
          )
        });
        //setIsLoading(false);
      }
    } catch(error) {
      console.error(error);
      throw error;
    }
  }

  const fetchModelAuthorizationsByOpmodelId = async (opmodelid) => {
    try {
      const authorizations = await apiRequest.get(`/opmodel/${opmodelid}/authorization`);

      const model = models && models.find((model) => model.id === opmodelid);
      if(model) {
        model.permissions = authorizations;
        if(!models) {
          setModels([model]);
        } else {
          setModels((oldModels) => {
            const mergedModels = _.unionBy(oldModels, model, "id");
            return mergedModels;
          });
        }
      } else {
        console.error("Non ho il modello disponibile")
      }

      return authorizations;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const getMyAuthorizationByOpmodelId = async (opmodelId) => {
    let model = models && models.find((model) => model.id === opmodelId);
    if(model === undefined) {
      model = await fetchModelById(opmodelId);
    }

    let { permissions } = model;
    if(permissions === undefined) {
      permissions = await fetchModelAuthorizationsByOpmodelId(opmodelId);
    }

    const myPermission = permissions.find(permission => permission.entity_identifier === email);
    return myPermission;
  }
 
  const addVersion = async (formData) => {
    try {
      console.log("ARRIVING DATA", formData);
      const data = await apiRequest.post(`/version/`, formData);
      data["opmodelId"] = data.opmodel.id.toString();
      data.tags = data.tags.map(tag => tag.model_tag);
      console.log("RECEIVING DATA", data);
      delete data.opmodel;
      setVersions(oldVersions => {
        if(!oldVersions) return [data];

        let allVersions = _.unionBy([data], oldVersions, "id");
        console.log("All new version are", allVersions);
        return allVersions;
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const addTagToVersion = async (versionId, tag) => {
    //setIsLoading(true);
    try {
      const tags = await apiRequest.post(`/version/${versionId}/tag`, JSON.stringify(tag))
      
      // trovare la version con versionId dallo stato
      // Se esiste, aggiorniamo i suoi tag con "tags"
      // aggiornare lo stato di versions

      const version = versions.find((version) => version.id === versionId);
      version.tags = tags.map((tag) => tag.model_tag);
      setVersions((oldVersions) => {
        return _.unionBy(
          oldVersions,
          [version],
          "id"
        )
      });

      //setIsLoading(false);
      return tags;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const removeVersionById = async (versionId) => {
    //setIsLoading(true);
    try {
      await apiRequest.get(`/version/${versionId}/info`);
      await apiRequest.delete(`/version/${versionId}`);

      let newVersions = [];
      
      setVersions(oldVersions => {
        if(!oldVersions) return;

        newVersions = JSON.parse(JSON.stringify(oldVersions));
        newVersions.splice(
          newVersions.indexOf(
            newVersions.find((version) => version.id === versionId)
          ), 
          1
        )
        
        return newVersions;
      });
      return newVersions;
      //setIsLoading(false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const removeTagFromVersion = async (versionId, tag) => {
    //setIsLoading(true);
    try {
      const tags = await apiRequest.delete(`/version/${versionId}/tag/${tag}`);

      const version = versions.find((version) => version.id === versionId);
      version.tags = tags.map((tag) => tag.model_tag);
      setVersions((oldVersions) => {
        return _.unionBy(
          oldVersions,
          [version],
          "id"
        )
      });
      //setIsLoading(false);
      return tags;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const addArtifactByVersionId = async (versionId, artifact) => {
    try {
      await apiRequest.put(`/version/${versionId}`, artifact);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const downloadVersionArtifactByHash = async (hash) => {
    try {
      const data = await apiRequest.get(`/version/${hash}/download`, {
        responseType: "blob"
      });
      return data;
    } catch (error) {
      console.error(error)
      throw error;
    }
  }

  return (
    <ModelsSettingsDataContext.Provider
      value={{
        models,
        versions,
        datasets,
        metrics,

        isModelsLoading,
        isVersionsLoading,
        isDatasetsLoading,
        isMetricsLoading,

        getModels,
        addModel,
        removeModelById,
        addVersion,
        removeVersionById,
        addTagToVersion,
        removeTagFromVersion,
        addArtifactByVersionId,
        downloadVersionArtifactByHash,
        addModelAuthorization,
        removeModelAuthorization,
        getMyAuthorizationByOpmodelId,
        fetchModelAuthorizationsByOpmodelId,

        // NEW METHODS
        getModelById,
        getVersionsByModelId,
        getMyPermissionByModelId,
        getVersionInfoById,
        updateModelById,
        getDatasets,
        getDatasetById,
        getDatasetsByVersionId,
        addDataset,
        addArtifactByDatasetId,
        downloadDataset,
        removeDatasetById,
        addMetric,
        getMetrics,
        getMetricsByVersionDatasetId,
        addArtifactByMetricId,
        downloadMetric,
        removeMetricById
      }}
    >
      {children}
    </ModelsSettingsDataContext.Provider>
  );
}

export { ModelsSettingsDataContext, ModelSettingsDataProvider };