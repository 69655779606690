import React from 'react'
import App from '../../App';


export default function Home() {


  return (
    /*showApp ?*/ <App /> /*: 
    <div className='flex h-full w-full items-center justify-center' style={{ backgroundColor: `#9ca79d`}}>
      <div className='flex items-center justify-center rounded-3xl bg-white h-2/4 w-96'>
        <button
          className='bg-am-600 rounded-3xl px-14 py-4 text-white hover:bg-am-700'
          onClick={() => {
            setShowApp(true);
          }}
        >Accedi al Model Registry</button>
      </div>
    </div>*/
  )
}
