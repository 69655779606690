// ##### DATE FUNCTIONALITIES ######
export const getDateOfISOWeek = (weekNumber, year) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export const getDateOfISOYearMonth = (year) => {
  return new Date(year, 0, 1);
}

export const getWeekFromDate = (inputDate) => {
  // given a date, returns the week number of that day
  const firstMonday = getNextMonday(new Date(inputDate.getFullYear(), 0, 1));
  const numberOfDays =  Math.floor((inputDate - firstMonday) / (24 * 60 * 60 * 1000));      // milliseconds in one day
  if (numberOfDays < 0)
    return 52;
  return Math.floor(numberOfDays / 7) + 1;
}

const getCurrentMondayDate = (inputDate) => {
  let result = new Date(inputDate);
  const dayNumber = (result.getDay() || 7) - 1;        // Get current day number, converting Sun. to 7
  result.setDate(result.getDate() - dayNumber);
  return result;
};

const getNextMonday = (inputDate) => {
  const lastMonday = getCurrentMondayDate(inputDate);

  let result = new Date(lastMonday);
  result.setDate(result.getDate() + 7);
  return result;
};

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getCurrentDateAndTime () {
  let today = new Date();
  let date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + ' ' + time;

  return dateTime;
}

export const compareStringsSemanticVersion = (a, b) => {
  if(a === "latest") {
    return -1;
  }

  if(b === "latest") {
    return 1;
  }
  // 1. Split the strings into their parts.
  const a1 = a.split('.');
  const b1 = b.split('.');
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length);
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
      const a2 = +a1[ i ] || 0;
      const b2 = +b1[ i ] || 0;
      
      if (a2 !== b2) {
          return a2 > b2 ? 1 : -1;        
      }
  }
  
  return b1.length - a1.length;
}


export const compareVersionTags = (a, b) => {
  if(a.tags.includes("latest")) {
    return 1;
  }

  if(b.tags.includes("latest")) {
    return -1;
  }

  const a1 = a.tags.filter(tag => tag.includes("."));
  const b1 = b.tags.filter(tag => tag.includes("."));

  if(a1.length === 0 || b1.length === 0) {
    if(a1.length === 0) return -1;
    if(b1.length === 0) return 1;
  } 

  const len = Math.min(a1[0].length, b1[0].length);

  for (let i = 0; i < len; i++) {
      const a2 = +a1[0][ i ] || 0;
      const b2 = +b1[0][ i ] || 0;
      
      if (a2 !== b2) {
          return a2 > b2 ? 1 : -1;        
      }
  }
  
  return b1[0].length - a1[0].length;
};