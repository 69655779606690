import axios from "axios";
import { API_URL } from "../utils/constants";
import { AuthenticationService } from "./newAuthenticationService";

const apiRequest = axios.create({
  baseURL: API_URL,
});

apiRequest.defaults.headers.common["Content-Type"] = "application/json";
// apiRequest.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

apiRequest.interceptors.request.use(
  async (config) => {
    const { publicRequest, audit } = config;

    if (publicRequest && !audit) {
      return config;
    }

    const access_token = await AuthenticationService.getAccessToken();

    if (!access_token && !audit) {
      throw new Error("user not logged");
    }

    if(access_token){
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiRequest.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiRequest;
