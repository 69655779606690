export const dataFaqIT = [
  {
    question: "Cosa sono queste FAQ?",
    answer: "Queste sono domande d'esempio, che devono essere sostituite dalle FAQ del singolo progetto!",
  },
  {
    question: "Non ricordo la password, come posso recuperarla?",
    answer: "Le tue password vengono salvate nel tuo Account Google. Per visualizzare un elenco di account con password salvate, visita la pagina passwords.google.com o visualizza le tue password in Chrome. Per visualizzare le password devi eseguire di nuovo l'accesso.",
  },
  {
    question: "Ho provato a registrarmi nel sito  ma il sistema mi dice che i dati esistono già. Come posso recuperare le credenziali?",
    answer: "Puoi effettuare il recupero credenziali via sms.",
  },
  {
    question: "Dopo aver recuperato le mie credenziali non riesco comunque ad accedere. Cosa posso fare?",
    answer: "Se sei già in possesso di un altro account Gmail ti consigliamo di utilizzare una finestra di navigazione in incognito o cambiare il browser in quanto i dati dell’altro account oppure della vecchia password potrebbero rimanere memorizzati.",
  },
];


export const dataFaqEN = [
  {
    question: "I don't remember the password, how can I recover it?",
    answer: "Your passwords are saved to your Google Account. To see a list of accounts with saved passwords, visit the passwords.google.com page or view your passwords in Chrome. To view passwords you need to sign in again.",
  },
  {
    question: "I tried to register on the site but the system tells me that my data already exists. How can I retrieve credentials?",
    answer: "You can recover credentials with sms system.",
  },
  {
    question: "After retrieving my credentials I still can't log in. What can I do?",
    answer: "If you already have another Gmail account, we recommend you to use a private browsing or change your browser as the data of the other account or the old password may remain stored.",
  },
];
