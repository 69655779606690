// Api URL based on env
const DEVELOPMENT_API_URL = "/api";
const PRODUCTION_API_URL = "https://services.ammagamma.com/amodelregistry";

export const API_URL = process.env.NODE_ENV === "production" ? PRODUCTION_API_URL : DEVELOPMENT_API_URL;

export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "user", label: "Utente base"}
]

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}

export const AWARE_CHART_COLORS = ["#a4243b", "#e59435", "#507e91", "#38632f", "#d8c99b"]
export const POST_FORECASTING_UM = 'Kg'