import React from "react";
import * as HIcons from '@heroicons/react/24/outline';

const Icon = ({iconName, className = ""}) => {
  const {...icons} = HIcons
  const SelectedIcon = icons[iconName]
  if(!SelectedIcon) {
    return null;
  }

  return (
    <>
      <SelectedIcon className={className} />
    </>
  );
};

export default Icon;
