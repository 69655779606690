import React, {useState} from "react";

// Services
import {useIntl, FormattedMessage} from "react-intl";

// UI
import {PageHeading} from "../../Container";
import FaqList from "./FaqList";
import Button from "../../Button";
import Modal from "../../Modal";
import DialogTicket from "./DialogTicket";


function Support() {
  const intl = useIntl();
  // const {push} = useContext(NotificationsContext);

  const [showAdd, setShowAdd] = useState(false);
  const [showSuccessResponse, setShowSuccessResponse] = useState(false);
  const toggleOpenDialog = () => setShowAdd(!showAdd);

  // ******  CUSTOMER PORTAL LINK function on comments ****** //

  // const [customerPortalLink, setCustomerPortalLink] = useState();

  // useEffect(() => {
  //   if (!customerPortalLink) {
  //     apiRequest
  //       .get("/tickets/customer_portal")
  //       .then(res => {
  //         if (res)
  //           setCustomerPortalLink(res)
  //       })
  //       .catch(() => {
  //         push({
  //           title: intl.formatMessage({id: "customer_portal_loading_failed"}),
  //           type: "error",
  //         });
  //       })
  //   }
  // }, [customerPortalLink, push, intl])

  return (
    <div>
      <PageHeading
        title={intl.formatMessage({id: "support"})}
        contentActions={
          <div className="flex flex-col items-center gap-5">
            <Button className="w-32" onClick={toggleOpenDialog}>
              <FormattedMessage id="button_faq"/>
            </Button>
            <a href="https://www.hubspot.com/products/service/customer-portal" target="blank">
              {intl.formatMessage({id: "hubspot_portal"})}
            </a>
          </div>
        }
      />
      <FaqList/>
      <DialogTicket
        opened={showAdd}
        toggleOpen={toggleOpenDialog}
        setShowSuccessResponse={setShowSuccessResponse}
      />
      <Modal
        opened={showSuccessResponse}
        buttonText="Test Modal"
        title="Grazie!"
        description={intl.formatMessage({id: 'success_ticket_sent'})}
        onExit={() => {setShowSuccessResponse(false)}}
      />
      {/* {customerPortalLink && <div className="flex justify-center">
        <a href={customerPortalLink} target="blank">
          {intl.formatMessage({id: "hubspot_portal"})}
        </a>
      </div>} */} 
    </div>
  );
}

export default Support;
