import React, { useEffect, useContext, useState, Fragment, useMemo } from "react";
import { Transition, Menu } from '@headlessui/react'
import { ModelsSettingsDataContext } from "../data";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Container from "../../../ui-components/Container";
import { compareVersionTags, compareStringsSemanticVersion } from "../../../utils";
import { NotificationsContext } from "../../../ui-components/Notifications";

import Badge from "../../../ui-components/Badge"
import Modal from "../../../ui-components/Modal"
import Placeholder from "../../../ui-components/Placeholder"
import Input from "../../../ui-components/Input";
import CheckboxGroup from "../../../ui-components/CheckboxGroup";
import SlideOver from "../../../ui-components/SlideOver";
import Button from "../../../ui-components/Button";
import FilePicker from "../../../ui-components/FilePicker";

import ModelCheckAuthorization, { MODEL_PERMISSIONS } from "./ModelCheckAuthorization";
import VersionDetailSlideOver from "./VersionDetail";

import { MagnifyingGlassIcon, CubeIcon, LinkIcon, UserPlusIcon, UserIcon, EllipsisVerticalIcon, PlusIcon, ArrowPathIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline'

const DEFAULT_EDIT_FORM_DATA = {
  "name": undefined,
  "type": undefined,
  "description": undefined,
  "bitbucket_repository" : undefined,
  "validator_id": null,
  "metadata_": {}
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ModelDetail() {
  let { modelId, versionId } = useParams();
  modelId = parseInt(modelId);
  versionId = parseInt(versionId);

  const history = useHistory();

  const { 
    isModelsLoading,
    isVersionsLoading,
    models, 
    versions, 
    removeModelById,
    downloadVersionArtifactByHash,
    getModelById,
    getVersionsByModelId,
    getMyPermissionByModelId,
    updateModelById
  } = useContext(ModelsSettingsDataContext);

  
  const [openSlideOverAuthorization, setOpenSlideOverAuthorization] = useState(false);
  const [openSlideOverVersionDetail, setOpenSlideOverVersionDetail] = useState(false);
  const [openSlideOverVersionAdd, setOpenSlideOverVersionAdd] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [input, setInput] = useState("");
  const [formData, setFormData] = useState(DEFAULT_EDIT_FORM_DATA);
  const [isEditingModel, setIsEditingModel] = useState(false);

  const refreshAllData = async () => {
    await getModelById(modelId);
    await getVersionsByModelId(modelId);
  }

  const handleSetOpenSlideOverVersionDetail = (open) => {
    if(!open) {
      history.push("/models/" + modelId + "/");
    }
  }

  const setFormDataValue = (key, value) => {
    setFormData(oldValue => {
      return {
        ...oldValue,
        [key]: value
      }
    })
  }

  useEffect(() => {
    refreshAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  useEffect(() => {
    if(versionId) {
      setOpenSlideOverVersionDetail(true);
    } else {
      setOpenSlideOverVersionDetail(false);
    }
  }, [versionId])

  useEffect(() => {
    if(isEditingModel) {
      setFormDataValue("name", aModel.name);
      setFormDataValue("type", aModel.type);
      aModel.validator_id ? setFormDataValue("validator_id", aModel.validator_id) : setFormDataValue("validator_id", null);
      setFormDataValue("bitbucket_repository", aModel.bitbucket_repository);
      setFormDataValue("description", aModel.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingModel])
  
  const aModel = useMemo(() => models && models.find((model) => model.id === modelId), [modelId, models]);
  //eslint-disable-next-line
  const aVersions = useMemo(() => versions && versions.filter((version) => version.opmodelId == modelId)
    .sort(compareVersionTags)
    .reverse()
    .map((version) => {
      version.tags = version.tags.sort(compareStringsSemanticVersion);
      return version;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [modelId, versions]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const myPermissions = useMemo(() => getMyPermissionByModelId(modelId), [modelId, models]);

  return (
    <Container>
      <div className="shadow-lg rounded-3xl px-7 py-6 bg-gray-50 border-2 border-gray-100 mt-9 mb-12">
        <div className="flex gap-3 items-center mb-4" style={{overflow: "clip"}}>
          {
            isEditingModel ? 
            (
              <Input
                value={formData.name ?? aModel.name}
                onChange={(e) => {
                  setFormDataValue("name", e.target.value);
                }}
                placeholder="Nome modello"
              />
            ) : 
            (
              <h2 className="text-2xl font-bold text-am-800">
                {aModel && aModel.name || <Placeholder width="w-48" height="h-7" />}
              </h2>
            )
          }
          <div className="flex flex-row items-center space-x-2 w-full justify-end">
            <Menu as="div" className="inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center items-center space-x-2 rounded-3xl bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none">
                  <UserIcon className="h-6 w-6" aria-hidden="true" />
                  <span>Utenti</span>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-50 z-10 mt-2 w-62 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {
                    aModel && aModel.permissions && aModel.permissions.map((permission) => (
                      <Menu.Item>
                        {({active}) => (
                          <span 
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {permission.entity_identifier.substring(0, 
                              permission.entity_identifier.indexOf("@") !== -1 ? permission.entity_identifier.indexOf("@") : permission.entity_identifier.length 
                            )}
                          </span>
                        )}
                          
                      </Menu.Item>
                    ))
                  }
                </Menu.Items>
              </Transition>
            </Menu>
            <Button
              styleType="white" 
              submitting={isModelsLoading || isVersionsLoading }
              className="space-x-2"
              onClick={() => {
                setIsEditingModel(false);
                setFormData(DEFAULT_EDIT_FORM_DATA);
                refreshAllData();
              }}
            >
              <ArrowPathIcon className="h-6 w-6 " aria-hidden="true"/>
              <span>Aggiorna</span>
            </Button>
          </div>
        </div>
        <div className="flex gap-x-2 gap-y-3 mb-3 flex-wrap">
          {/* -------------------- Badges -------------------- */}
          {
            aModel ?
              <Badge
                text={aModel.type === "nn" ? "Neural Network" : "Machine Learning"}
              /> :
              <Placeholder width="w-36" height="h-5" />
          }
          {
            aModel && aModel.validator_name ?
              <Badge
                text={aModel.validator_name}
              /> :
              null
          }
        </div>
        {/* -------------------- Metadata -------------------- */}
        <div className="my-7">
          {
            aModel
              ? 
              (
                aModel.description ?
                  isEditingModel ? <Input
                    value={formData.description?? aModel.description}
                    onChange={(e) => {
                      setFormDataValue("description", e.target.value);
                    }}
                    placeholder="Descrizione"
                  />  : aModel.description
                : 
                <span className="text-gray-400">Descrizione non disponibile</span>
              )
              : <Placeholder width="w-48 sm:w-96" />
          }
        </div>
        {
          aModel && aModel.bitbucket_repository
            ? (
              <div className="flex flex-row items-center text-xs gap-1 mb-1 text-gray-500">
                Repository:&nbsp;
                {

                  isEditingModel ? 
                    <Input
                    value={formData.bitbucket_repository ?? aModel.bitbucket_repository}
                    onChange={(e) => {
                      setFormDataValue("bitbucket_repository", e.target.value);
                    }}
                    placeholder="Repository"
                  />
                  : 
                    <a
                      href={`https://bitbucket.org/${aModel.bitbucket_repository}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center"
                      style={{"color": "var(--c-light-blue)"}}
                    >
                      <LinkIcon className="w-4 h-4 mr-0.5" />
                      <span>{aModel.bitbucket_repository}</span>
                    </a>
                }
              </div>
            )
            : null
        }
        <div className="flex gap-2 text-xs text-gray-500">
          <div>Data di creazione:</div>
          {
            aModel && aModel.created_at ?
              <div>{new Date(aModel.created_at).toLocaleDateString()}</div> :
              <Placeholder width="w-28" />
          }
        </div>

        <div className="flex flex-row items-center gap-2 mt-4">
          <ModelCheckAuthorization
            permission={myPermissions}
            check={MODEL_PERMISSIONS.UPDATE}
          >
            <button
              type="button"
              className="space-x-1 bg-am-600 inline-flex items-center rounded-full border border-transparent p-2 mb-2 ml-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={ async () => {
                setOpenSlideOverVersionAdd(true);
              }}
            >
              <PlusIcon className="h-6 w-6" aria-hidden="true"/>
              <span>Aggiungi versione</span>
            </button>
          </ModelCheckAuthorization>
          
          <ModelCheckAuthorization
            permission={myPermissions}
            check={MODEL_PERMISSIONS.SHARE}
          >
            <button
              type="button"
              className="space-x-1 inline-flex items-center rounded-full border border-transparent bg-am-600 p-2 mb-2 ml-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={ async () => {
                setOpenSlideOverAuthorization(true);
              }}
            >
              <UserPlusIcon className="h-6 w-6" aria-hidden="true"/>
              <span>Gestisci permessi</span>
            </button>
          </ModelCheckAuthorization>

          <ModelCheckAuthorization
              permission={myPermissions}
              check={MODEL_PERMISSIONS.UPDATE}
            >
              <button 
                type="button"
                onClick={async () => {
                  if(isEditingModel) {
                    await updateModelById(aModel.id, formData)

                    setIsEditingModel(false);
                    setFormData(DEFAULT_EDIT_FORM_DATA);
                    refreshAllData();
                  } else {
                    setIsEditingModel(true);
                  }
                }}

                className="space-x-1 inline-flex items-center rounded-full border border-transparent bg-am-600 p-2 mb-2 ml-2 text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              > 
                {isEditingModel ? <CheckIcon className="h-6 w-6 " aria-hidden="true"/> : <PencilIcon className="h-6 w-6 " aria-hidden="true"/>}
                {isEditingModel ? <span>Conferma modifiche</span> : <span>Modifica modello</span>}
              </button>
            </ModelCheckAuthorization>
        </div>
      </div>

      <div className="mb-12">
        <div className="w-full mb-2 flex flex-row items-center justify-between rounded-2xl px-2 pt-2 pb-4 space-x-2">
          <h3 className="font-bold text-2xl text-gray-900">Versioni disponibili</h3>

          <div className="flex flex-row items-center space-x-3">
            <MagnifyingGlassIcon className="h-6 w-6 opacity-40"/>
            <Input 
              value={input} 
              onChange={(e) => {
                setInput(e.target.value);
              }} 
              placeholder="Cerca con tag">  
            </Input>
          </div>

        </div>

        <div className="flex items-stretch justify-center w-full">
          <ul className="grid gap-y-2 gap-x-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            { 
              aModel && aVersions && aVersions.filter((version) => {
                return input === "" ? true : version.tags.some((tag) => tag.includes(input))
              })
              .map((version) => {
                return (
                  <li key={version.id} className="w-full">
                    <button
                      onClick={() => {
                        history.push(`/models/${modelId}/version/${version.id}`);
                        // setOpenSlideOverVersionDetail(true);
                        // setSelectedVersionDetail(version);
                      }}
                      className="transition duration-200 transform ease-in-out hover:-translate-y-2 w-full"
                    >
                      <VersionCard 
                        version={version}
                        model={aModel}
                        downloadArtifact={downloadVersionArtifactByHash}
                      />
                    </button>
                  </li>  
                );
              })
            }
            {
              !aVersions && <VersionCardPlaceholder />
            }
          </ul>
        </div>
      </div>
      
      <ModelCheckAuthorization
        permission={myPermissions}
        check={MODEL_PERMISSIONS.DELETE}
      >
        <div>
          <h3 className="font-bold text-2xl text-gray-900">Danger zone</h3>
          <div className="mt-5 bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Elimina questo modello</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Una volta eliminato, perderai TUTTI i dati associati</p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                  onClick={() => {
                    setOpenDeleteModal(true);
                    /*removeModelById(modelId);
                    history.push("/models");*/
                  }}
                >
                  Elimina
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModelCheckAuthorization>
      
      <Modal
        opened={openDeleteModal}
        title={"Conferma eliminazione"}
        description={"Sei sicuro di voler eliminare il modello? Perderai TUTTI I DATI ASSOCIATI"}
        onConfirm={() => {
          setOpenDeleteModal(false);
          removeModelById(modelId);
          history.push("/models");
        }}
        onDismiss={() => {
          setOpenDeleteModal(false);
        }}
        onExit={() => {
          setOpenDeleteModal(false);
        }}
        confirmText = "ELIMINA"
        dismissText = "ANNULLA"
        //type={"danger"}
      >
      </Modal>

      <VersionDetailSlideOver
        open={openSlideOverVersionDetail}
        setOpen={handleSetOpenSlideOverVersionDetail}
        modelId={modelId}
        versionId={versionId}

        downloadArtifact={downloadVersionArtifactByHash}
      />


      {/*Slide over authorization*/}
      <ModelAuthorizationSlideOver 
        open={openSlideOverAuthorization}
        setOpen={setOpenSlideOverAuthorization}
        model={aModel}
      />

      <VersionAddSlideOver 
        open={openSlideOverVersionAdd}
        setOpen={setOpenSlideOverVersionAdd}
        model={aModel}
        refresh={refreshAllData}
      />
    </Container>
  );
}

function TagBadge({ tagName }) {
  return <Badge text={tagName} type={tagName === "latest" ? "success" : "warning"} classNames="p-2"></Badge>
}

function VersionCard({
  version,
  model
}) {
  return (
    <div className="rounded-3xl bg-white shadow-lg">
      <div className="flex w-full items-center justify-between py-4 pr-12 pl-4">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between">
            <CubeIcon className="w-10 h-10"/>
            <h3 className="truncate text-xl font-bold text-gray-900">{model.name}:<span className="font-extralight">{version.hash ? version.hash.substring(0, 6) : ""}</span></h3>
          </div>
          <div className="flex flex-row flex-wrap items-center space-x-2 mt-2">
            <p className="text-base text-gray-500">Tags: </p>
            {version.tags.map((tag) => {
                return (<TagBadge key={tag} tagName={tag} />);
            })}
          </div>

          <div className="flex flex-row items-center justify-between mt-2">
            <span className="text-base opacity-30">Creata il: {new Date(version.created_at).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function VersionCardPlaceholder() {
  return (
    <div className="col-span-1 divide-y divide-gray-200 rounded-3xl bg-white shadow">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <CubeIcon className="w-8 h-8"/>
            <Placeholder width="w-48" height="h-7"/>
          </div>
          <div className="flex flex-row items-center space-x-3 mt-2">
            <p className="text-sm text-gray-500">Tags: </p>
            <Placeholder width="w-48" height="h-7"/>

          </div>

          <div className="flex flex-row items-center space-x-3 mt-2">
          </div>
        </div>
      </div>
    </div>
  );
}

function ModelAuthorizationSlideOver({
  open,
  setOpen,
  model
}) {
  const DEFAULT_FORM_DATA = {
    "entity_type": "",
    "entity_identifier": "",
    "permissions": 1
  }

  const { addModelAuthorization, removeModelAuthorization } = useContext(ModelsSettingsDataContext);
  const { push } = useContext(NotificationsContext);


  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
  const [checkboxGroup, setCheckboxGroup] = useState([]);

  const calculatePermissions = () => {
    const permissionValue = checkboxGroup.reduce((partialSum, a) => partialSum + a, 0);
    setFormDataValue("permissions", permissionValue);
  }

  const calculateAuthorization = (permissionValue) => {
    const fixed = "(PuÃ² ";
    let string = "";

    switch(permissionValue) {
      case 1 : {
        string = fixed + "visualizzare)";
        break;
      }
      case 2 : {
        string = fixed + "modificare)";
        break;
      }
      case 4 : {
        string = fixed + "eliminare)";
        break;
      }
      case 8 : {
        string = fixed + "condividere)";
        break;
      }
      case 15 : {
        string = "(Admin)";
        break;
      }
      case 3 : {
        string = fixed + "visualizzare e modificare)";
        break;
      }
      case 5 : {
        string = fixed + "visualizzare ed eliminare)";
        break;
      }
      case 9 : {
        string = fixed + "visualizzare e condividere)";
        break;
      }
      case 7 : {
        string = fixed + "visualizzare, modificare ed eliminare)";
        break;
      }
      case 11 : {
        string = fixed + "visualizzare, modificare e condividere)";
        break;
      }

      default : {
        string = "Error";
      }
    }

    return string;
  }

  const setFormDataValue = (key, value) => {
    setFormData(oldValue => {
      return {
        ...oldValue,
        [key]: value
      }
    })
  }

  useEffect(() => {
    calculatePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxGroup])


  if(!model) return null;
  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={
        <div className="">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-xl font-bold text-gray-900">Gestione permessi</h3>
          </div>
          <p className="text-sm text-gray-500">
          </p>
        </div>
      }
    >
      <div>
        <div className="px-6 relative">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
            <div>
              <label
                htmlFor="project-name"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Tipo di entitÃ 
              </label>
            </div>
            <div className="sm:col-span-2">
              <Select 
                className="pb-2"
                placeholder="Tipo di entitÃ "
                isSearchable
                value={formData.entity_type && {label: formData.entity_type, value: formData.entity_type}}
                options={[{value: "client", label: "Client"}, {value: "user", label: "User"}, {value: "organization", label: "Organization"}]}
                onChange={(e) => {
                  setFormDataValue("entity_type", e.value);
                }}
              />
            </div>
          </div>

          {/* Project description */}
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
            <div>
              <label
                htmlFor="project-description"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Identificativo entitÃ 
              </label>
            </div>
            <div className="sm:col-span-2">
              <Input 
                value={formData.entity_identifier}
                onChange={(e) => {
                  setFormDataValue("entity_identifier", e.target.value);
                }}
                placeholder="Identificativo entitÃ "
              />
            </div>
          </div>

          {/* Privacy */}
          <fieldset className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:py-5">
            <legend className="sr-only">Permessi</legend>
            <div className="text-sm font-medium text-gray-900" aria-hidden="true">
              Permessi
            </div>
            
            <CheckboxGroup
              horizontal
              options={[
                { value: 1, name: "Visualizzare" },
                { value: 2, name: "Modificare" },
                { value: 4, name: "Eliminare" },
                { value: 8, name: "Condividere"}
              ]}
              values={checkboxGroup}
              onChange={(value) => {
                if (checkboxGroup.indexOf(value) >= 0) {
                  setCheckboxGroup([...checkboxGroup.filter((v) => v !== value)]);
                } else {
                  setCheckboxGroup([...checkboxGroup, value]);
                }
              }}
            />
          </fieldset>

          <div className="flex-shrink-0 border-t border-gray-200 py-5">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-am-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
                onClick={() => {
                  if(formData.entity_identifier !== "" && formData.entity_type !== "" && checkboxGroup.length > 0) {
                    try {
                      push({
                        title: "Stato permessi",
                        text: "Aggiungo i permessi.."
                      })
          
                      addModelAuthorization(model.id, formData).then(() => {
                        push({
                          title: "Stato permessi",
                          text: "Permessi aggiunti con successo!"
                        })
                      }).catch((error) => {
                        push({
                          title: "Stato permessi",
                          text: error.message
                        })
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  } else {
                    push({
                      title: "Stato permessi",
                      text: "Campi mancanti!"
                    })
                  }
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
        
        <div className="shadow-lg rounded-3xl px-2 mx-4 bg-gray-50 border-2 border-gray-100 mb-4">
          <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
            {model.permissions && model.permissions.map((permission) => (
              <li key={permission.id}>
                <div className="group relative flex items-center py-6">
                  <div className="-m-1 block flex-1 p-1">
                    <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                    <div className="relative flex min-w-0 flex-1 items-center">
                      <span className="relative inline-block flex-shrink-0">
                        <UserIcon className="h-10 w-10" />
                        <span
                          className=""
                          aria-hidden="true"
                        />
                      </span>
                      <div className="ml-4 truncate">
                        <p className="truncate text-sm font-medium text-gray-900">{permission.entity_identifier}</p>
                        <p className="truncate text-sm text-gray-500">{permission.entity_type}  {calculateAuthorization(permission.permissions)}</p>
                      </div>
                    </div>
                  </div>
                  <Menu as="div" className="relative ml-2 mr-2 inline-block flex-shrink-0 text-left">
                    <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2">
                      <span className="sr-only">Open options menu</span>
                      <span className="flex h-full w-full items-center justify-center rounded-full">
                        <EllipsisVerticalIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute top-0 right-9 z-10 w-18 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={
                                  classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-2 py-2 text-sm'
                                  )
                                }

                                onClick={() => {
                                  removeModelAuthorization(model.id, permission.entity_identifier);
                                }}
                              >
                                Rimuovi
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SlideOver>
  )
}

function VersionAddSlideOver ({
  open,
  setOpen,
  model,
  refresh
}) {

  const DEFAULT_FORM_DATA = {
    "name": undefined,
    "type": undefined,
    "description": undefined,
    "bitbucket_repository" : undefined,
    "bitbucket_commit": "",
    "storage_type": "s3",
    "model_tags": [],
    "validator_id": 0
  }

  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  const [artifactData, setArtifactData] = useState(undefined);
  const { push } = useContext(NotificationsContext);

  const { 
    addVersion,
    addArtifactByVersionId
  } = useContext(ModelsSettingsDataContext);

  const setFormDataValue = (key, value) => {
    key === "model_tags" ? setFormData(oldValue => {
      return {
        ...oldValue,
        [key]: value.length === 0 ? [] : 
                value[0].label && value.length === 1 ? [value[0].label] : 
                  value[0].label ? oldValue.model_tags.filter(tag => {
                    return value.some((element) =>  {
                      return tag === element.label
                    })
                  }) : 
                    oldValue.model_tags.concat(value)
      }
    }) : setFormData(oldValue => {
      return {
        ...oldValue,
        [key]: value
      }
    })
  }

  /*const handleAddVersion = async () => {

  }*/

  useEffect(() => {
    if(model) {
      setFormDataValue("name", model.name);
      setFormDataValue("type", model.type);
      setFormDataValue("bitbucket_repository", model.bitbucket_repository);
    }
  }, [model])

  const Header = () => {
    return (
      <div className="space-y-1">
        <div className="flex items-center space-x-3">
          <CubeIcon className="w-8 h-8"/>
          <h1 className="truncate text-xl font-bold text-gray-900">{<span className="font-medium text-lg">Aggiungi versione a "{model.name}"</span>}</h1>
        </div>
        <p className="text-sm text-gray-500">
        </p>
      </div>
    );
  }

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={<Header />}
    >
      <div className="px-6">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
          <div>
            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              Descrizione versione<sup className="text-red-500 font-bold text-sm">*</sup>
            </label>
          </div>
          <div className="sm:col-span-2">
            <Input        
              type="textarea"        
              value={formData.description}
              onChange={(e) => {
                setFormDataValue("description", e.target.value);
              }}
              placeholder="Descrizione versione"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
          <div>
            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              Tags<sup className="text-red-500 font-bold text-sm">*</sup>
            </label>
          </div>
          <div className="sm:col-span-2">
            <CreatableSelect 
              className="pb-2"
              isMulti
              placeholder="Tags"
              value={formData.model_tags && formData.model_tags.map((tag) => {return {label: tag, value: tag}})}
              onCreateOption={(e) => {
                setFormDataValue("model_tags", e);
              }}
              onChange={(e) => {
                setFormDataValue("model_tags", e)
              }}
              isClearable={false}
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
          <div>
            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              Artefatto<sup className="text-red-500 font-bold text-sm">*</sup>
            </label>
          </div>
          <div className="sm:col-span-2">
            <FilePicker 
              className="pb-2"
              fileChosen={artifactData}
              setFileChosen={file => setArtifactData(file)}
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-2">
          <div>
            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              Hash commit Bitbucket
            </label>
          </div>
          <div className="sm:col-span-2">
            <Input      
              value={formData.bitbucket_commit}
              onChange={(e) => {
                setFormDataValue("bitbucket_commit", e.target.value);
              }}
              placeholder="Hash commit Bitbucket"
            />
          </div>
        </div>

        <div className="flex-shrink-0 border-t border-gray-200 py-5">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={() => {
                setFormData(DEFAULT_FORM_DATA);
                setArtifactData();
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-am-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-am-700 focus:outline-none focus:ring-2 focus:ring-am-500 focus:ring-offset-2"
              onClick={ async () => {

                if(formData.name === undefined || 
                  formData.type === undefined ||
                  formData.description === undefined ||
                  formData.bitbucket_repository === undefined ||
                  formData.model_tags.length === 0 ||
                  artifactData === undefined
                ) {
                  push({
                    title: "Stato modello",
                    text: "Campi mancanti!"
                  })
                  return;
                }

                try {
                  push({
                    title: "Stato versione",
                    text: "Aggiungo la versione (1/2)",
                  })

                  let versionCreated;

                  try {
                    versionCreated = await addVersion(formData);
                  } catch (error) {
                    push({
                      title: "Impossibile caricare la versione",
                      text: "Errore con il caricamento della versione"
                    })
                    console.warn("Error during version creation ", error);
                    return;
                  }
                  
                  const versionId = versionCreated.id;
                  const fileData = new FormData();
                  fileData.append("version_file", artifactData[0])
  
                  push({
                    title: "Stato versione",
                    text: "Carico l'artefatto(2/2)",
                  })

                  await addArtifactByVersionId(versionId, fileData);
                  refresh();

                  push({
                    title: "Stato versione",
                    text: "Upload completato con successo!",
                  })

                  setFormData(DEFAULT_FORM_DATA);
                  setArtifactData();
                  setOpen(false);
                    
                } catch(error) {
                  push({
                    title: "Stato versione",
                    text: error
                  })
                } 
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </SlideOver>
  );

}

export default ModelDetail;
