import React from "react";
import SelectRole from "./SelectRole";

export const getColumns = (intl) => [
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
  },
  {
    title: intl.formatMessage({ id: "role" }),
    field: "role_id",
    render: ({ role }) => <b className="capitalize">{role.name}</b>,
    editComponent: (props) => <SelectRole {...props} />,
  },

  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_user" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "users_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "users"}),
  },
});
