import React, {useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";


function Login() {
  const { loginWithRedirect } = useAuth0();

  /*const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [error, setError] = useState("");
  const [errorForgot, setErrorForgot] = useState("");
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [oauth2Url, setOauth2Url] = useState('');
  const setOnError = (msg) => setError(msg);
  const setOffError = () => setError("");
  const setOnErrorForgot = (msg) => setErrorForgot(msg);
  const setOffErrorForgot = () => setErrorForgot("");

  useEffect(() => {
    if (!oauth2Url) {
      AuthenticationService.getOauth2Url()
        .then((url) => {
          setOauth2Url(url);
        })
        .catch(() => {
          console.warn("Cannot get OAuth2 url. OAuth2 Disabled.");
        });
    }

    if (window.location.href.includes("unauthorized_user")) {
      setOnError(intl.formatMessage({id: "access_denied"}));
    }
  }, [oauth2Url, intl]);

  function toggleForgot() {
    setEmailSent(false);
    setOpenForgot(!openForgot);
  }

  async function recoverPassword(email) {
    try {
      await apiRequest.post(
        "/users/password/forgot",
        {email},
        {publicRequest: true}
      );
      setOffErrorForgot();
      setEmailSent(true);
    } catch (error) {
      setOnErrorForgot(intl.formatMessage({id: "unregistered_email"}));
    }
  }

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    await recoverPassword(email);
    setLoading(false);
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (!username || !password) {
        return;
      }
      setLoading(true);
      setOffError();
      await AuthenticationService.login(username.trim(), password);
    } catch (error) {
      setOnError(intl.formatMessage({id: "wrong_email_or_password"}));
      setPassword("");
      setLoading(false);
    }
  };

  if (AuthenticationService.isUserAuthenticated()) {
    return <Redirect to="/home"/>;
  }

  const getOAuth2Button = () => {
    return (
      <Button
        full
        styleType="white"
        className="mt-2"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = oauth2Url;
        }}
      >
        Single Sign On
      </Button>
    )
  }*/

  useEffect(() => {
    const handleLogin = async () => {
      loginWithRedirect().then( async (value) => {
      });
    };
    
    handleLogin()
  }, [loginWithRedirect]);

  return <></>;
}

export default Login;
