import "./css/index.css";
import Home from "./ui-components/pages/Home";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import React from "react";

import Auth0ProviderWithHistory from "./auth/auth0-provider";

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <Home />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
);
